import Button from "common/components/Button/Button";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface IHeaderButtonsEdit {
  onSubmit?: any;
  onCancel?: any;
  isChangingStatus?: boolean;
  unpublishDisabled?: boolean;
  onUnpublish?: () => void;
  expirateDisabled?: boolean;
  onExpirate?: () => void;
  unexpirateDisabled?: boolean;
  onUnexpirate?: () => void;
  onValidate?: () => void;
  validateDisabled?: boolean;
  onSynchronize?: () => void;
  synchronizeLabel?: string;
  synchronizeDisabled?: boolean;
  inHeader?: boolean;
}

function HeaderButtonsEdit({
  isChangingStatus,
  unpublishDisabled,
  onUnpublish,
  expirateDisabled,
  onExpirate,
  unexpirateDisabled,
  onUnexpirate,
  onValidate,
  validateDisabled,
  onSynchronize,
  synchronizeLabel,
  synchronizeDisabled,
  onSubmit,
  onCancel,
  inHeader = false,
}: IHeaderButtonsEdit) {
  const { t } = useTranslation();

  const style = inHeader
    ? "hidden md:flex md:flex-col lg:flex-row lg:absolute lg:-bottom-10 lg:right-0 flex-wrap gap-2 lg:items-center mt-2 lg:mt-0"
    : "flex flex-col md:hidden gap-2 white-box mt-4";

  return (
    <div className={style}>
      {/* modo actions */}
      {onValidate && !validateDisabled && (
        <Button
          title={t("actions.validate")}
          type="valid-line"
          icon="CheckIcon"
          onClick={onValidate}
          loading={isChangingStatus}
        />
      )}
      {onUnpublish && !unpublishDisabled && (
        <Button
          title={t("actions.unpublish")}
          type="error-line"
          icon="MdOutlineUnpublished"
          onClick={onUnpublish}
          loading={isChangingStatus}
        />
      )}
      {onExpirate && !expirateDisabled && (
        <Button
          title={t("actions.expirate")}
          type="error-line"
          icon="EyeSlashIcon"
          onClick={onExpirate}
          loading={isChangingStatus}
        />
      )}
      {onUnexpirate && !unexpirateDisabled && (
        <Button
          title={t("actions.republish")}
          type="valid-line"
          icon="EyeIcon"
          onClick={onUnexpirate}
          loading={isChangingStatus}
        />
      )}
      {onSynchronize && !synchronizeDisabled && (
        <Button
          title={synchronizeLabel}
          className="normal-case"
          type="purple-line"
          icon="IoRefreshCircle"
          onClick={onSynchronize}
          loading={isChangingStatus}
        />
      )}
      {/* writers actions */}
      {onCancel && (
        <Button
          title={t("actions.cancel")}
          type="neutral"
          icon="XCircleIcon"
          onClick={onCancel}
        />
      )}
      {onSubmit && (
        <Button
          title={t("actions.save")}
          type="primary"
          icon="CheckCircleIcon"
          onClick={onSubmit}
        />
      )}
    </div>
  );
}

export default HeaderButtonsEdit;
