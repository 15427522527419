import { capitalize } from "lodash";

const getWording = (plural?: boolean, capital?: boolean) => {
  if (plural) {
    return capital ? capitalize("parkings") : "parkings";
  }
  return capital ? capitalize("parking") : "parking";
};

export default getWording;
