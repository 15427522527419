import { Link } from "react-router-dom";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";
import Button from "common/components/Button/Button";
import truncateString from "common/utils/truncateString";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import getFlattenText from "common/utils/getFlattenText";
import useAuth from "common/hooks/useAuth";
import { Partner } from "types/Partner";
import { StarIcon } from "@heroicons/react/24/solid";
import Icon from "components/Icon/Icon";

const containerStyle =
  "flex px-4 py-4 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all overflow-hidden";

function PartnerItem({ item, onDelete, onNavigate }: any) {
  const {
    id,
    title,
    description,
    images,
    addressTitle,
    street,
    city,
    postalCode,
    rating,
    contactName,
    contactPhoneNumber,
  } = item as Partner;

  const { t } = useTranslation();
  const { user } = useAuth();

  const editLink = `/partners/edit/${id}`;

  return (
    <div
      className={`${containerStyle}  items-start lg:items-stretch flex-col lg:flex-row`}
    >
      <Link to={editLink} className="w-full">
        <div className="responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4">
          <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
            <div className="absolute -top-2 -left-2 w-full h-full rounded-lg overflow-hidden">
              <ImageComponent
                image={
                  images?.[0]
                    ? ImageFromStrapiMedia(images?.[0])?.uri
                    : undefined
                }
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div className="flex flex-col xl:flex-row w-full gap-4">
              <div className="flex flex-col flex-1">
                <h4 className="text-xl font-bold text-primary leading-6">
                  {title}
                </h4>
                <p>
                  <b>{addressTitle}</b> : {street} {postalCode} {city}
                </p>
                {(contactName || contactPhoneNumber) && (
                  <div className="flex flex-row gap-4 items-center">
                    {contactName && (
                      <div className="flex flex-row items-center">
                        <Icon name="UserIcon" className="w-4 h-4 mr-1" />
                        <p>{contactName}</p>
                      </div>
                    )}
                    {contactName && (
                      <div className="flex flex-row items-center">
                        <Icon name="PhoneIcon" className="w-4 h-4 mr-1" />
                        <p>{contactPhoneNumber}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="responsive-flex-lg mt-1" />

            {/* foot */}
            <div className="flex flex-col gap-2 pt-1 border-t border-slate-200">
              {!!description && (
                <div className="text-sm text-gray-500 pt-2">
                  {truncateString(getFlattenText(description), 150)}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>

      {/* right */}
      <div className="flex flex-col justify-between text-xs w-full lg:border-l lg:pl-4 lg:w-[30em] lg:border-slate-150 gap-2">
        <div className="flex flex-row gap-1 items-center">
          <StarIcon width={25} className="text-yellow-500" />

          <p className="text-gray-600 leading-6 text-xl">
            <b className="text-gray-900">{rating}</b>/5
          </p>
        </div>

        {user?.isSuperAdmin && (
          <div className="flex w-full gap-1 justify-end">
            <Button
              type="error-line"
              icon="TrashIcon"
              compact
              onClick={() => onDelete(item?.id)}
              confirmMessage={t("actions.confirmDelete")}
            />

            {user?.isJuloaAdmin && (
              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item?.id)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PartnerItem;
