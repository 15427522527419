// import Loader from 'common/components/Loader/Loader';
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Content as ContentType } from "types/Content";
import ContentForm from "./ContentForm";

function Content() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any =
    useItem<ContentType>("contents", id, {
      populate: ["category", "author", "images"],
    });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/contents");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (values: any) => {
      const newValues = { ...values };

      try {
        if (newValues.id) {
          await updateItem(newValues.id, newValues);
        } else {
          await saveItem(newValues);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <ContentForm onSubmit={onSubmit} content={item} />
  );
}

export default Content;
