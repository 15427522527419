import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import { Content } from "types/Content";
import CategorySelect from "components/select/CategorySelect/CategorySelect";
import UserSelect from "components/select/UserSelect/UserSelect";
import useList from "common/hooks/useList";
import { Field } from "types/Field";
import ContentStatusSelect from "components/select/ContentStatusSelect/ContentStatusSelect";

type ContentFormProps = {
  content: Content | undefined;
  onSubmit: (values: any) => any;
};

function ContentForm({ onSubmit, content }: ContentFormProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();
  const { items: fields } = useList<Field>("fields", {
    populate: { categories: { fields: ["id"] } },
    defaultSort: "order",
    overrideItemsPerPage: 5000
    
  });

  const initialValues = useMemo(
    () =>
      content?.id
        ? {
            ...content,
            category: content.category?.id,
            author: content.author?.id,
          }
        : {
            name: "",
            label: "",
          },
    [content]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([f]: any, state: any, utils: any) => {
          utils.changeValue(state, f.field, () => f.value);
        },
      }}
      render={({ handleSubmit, values }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(content?.id ? "editItem.content" : "newItem.content")}
              subTitle={values.name}
              icon="HandThumbUpIconSolid"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/contents")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex gap-2 mt-2">
                <div className="md:w-2/3 flex flex-col gap-2">
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="title"
                      title={t("common.title")}
                      required
                    />
                    <FormControl
                      className="w-full"
                      name="description"
                      type="textarea"
                      titleKey={t("forms.description")}
                      required
                    />
                    <FormControl
                      name="price"
                      type="integer"
                      titleKey={t("forms.price")}
                    />
                    <FormControl name="address" titleKey={t("forms.address")} />
                    <FormControl
                      title={t("forms.images")}
                      labelIcon="PhotoIcon"
                      name="images"
                      type="images"
                      limit={4}
                    />
                  </div>
                  <div className="white-box flex flex-col w-full gap-2">
                    {fields?.map((f) => {
                      if (
                        f.categories?.find(({ id }) => id === values.category)
                      ) {
                        return (
                          <FormControl
                            name={`extraFields.${f.name}`}
                            title={f.label}
                            type={f.type}
                            placeholder={f.placeholder}
                            helpText={f.description}
                            required={f.required}
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
                <div className="flex flex-col md:w-1/3 gap-2">
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="status"
                      titleKey="common.status"
                      type="custom"
                      disabled
                    >
                      <ContentStatusSelect />
                    </FormControl>

                    <FormControl
                      name="publishedDate"
                      titleKey={t("forms.publishedDate")}
                      type="date"
                    />
                    <FormControl
                      name="endPublishedDate"
                      titleKey={t("forms.endPublishedDate")}
                      type="date"
                    />
                  </div>
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="category"
                      titleKey={t("forms.category")}
                      type="custom"
                    >
                      <CategorySelect />
                    </FormControl>
                    <FormControl
                      name="author"
                      titleKey={t("forms.author")}
                      type="custom"
                    >
                      <UserSelect />
                    </FormControl>
                  </div>
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="canExchange"
                      titleKey={t("forms.canExchange")}
                      type="switch"
                    />
                    <FormControl
                      name="useSafeMeetingPlace"
                      titleKey={t("forms.useSafeMeetingPlace")}
                      type="switch"
                    />
                    <FormControl
                      name="isChapChap"
                      titleKey={t("forms.isChapChap")}
                      type="switch"
                    />
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/contents")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default ContentForm;
