/* eslint-disable no-console */
import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import fetchJSON from "common/utils/fetchJSON";
import { TransactionsNumber } from "types/Transaction";

type TransactionProviderProps = {
  transactionsNumber: TransactionsNumber;
  refreshTransactionsNumber: () => Promise<TransactionsNumber>;
  isFetching: boolean;
};

export const TransactionContext = createContext<TransactionProviderProps>(
  {} as TransactionProviderProps
);

type Props = {
  children: React.ReactNode;
};

const defaultTransactionsNumber: TransactionsNumber = {
  all: 0,
  opened: 0,
  waiting_answer: 0,
  price: 0,
  meeting: 0,
  rejected: 0,
  transaction_waiting_validation: 0,
  transaction_validated: 0,
  cancelled: 0,
  review: 0,
};

export function TransactionProvider({ children }: Props) {
  const [isFetching, setIsFetching] = useState(false);
  const [transactionsNumber, setTransactionsNumber] =
    useState<TransactionsNumber>(defaultTransactionsNumber);

  const refreshTransactionsNumber = useCallback(async () => {
    try {
      setIsFetching(true);

      const res = await fetchJSON({
        url: `transactions/data/number`,
        method: "GET",
      });

      if (res) {
        setTransactionsNumber(
          res
            ? {
                ...res,
              }
            : undefined
        );
      } else {
        setTransactionsNumber(defaultTransactionsNumber);
      }

      return res ?? false;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      setIsFetching(false);
    }
  }, [setTransactionsNumber]);

  useEffect(() => {
    const firstLoad = async () => {
      await refreshTransactionsNumber();
    };

    firstLoad();
  }, [refreshTransactionsNumber]);

  const value: TransactionProviderProps = useMemo(
    () => ({
      transactionsNumber,
      refreshTransactionsNumber,
      isFetching,
    }),
    [transactionsNumber, isFetching, refreshTransactionsNumber]
  );

  return (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  );
}
