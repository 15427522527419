/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  useState,
  useRef,
  useCallback,
  useEffect,
  ChangeEvent,
  ReactNode,
} from "react";
import { useTranslation } from "react-i18next";
import { arrayMoveImmutable } from "array-move";

import { getStoredData } from "common/utils/fnAsyncStorage";
import Button from "common/components/Button/Button";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import DefaultFilesList from "./DefaultFilesList";
import { getCroppedImg } from "./utils";
import { Constants } from "../../../constants/Constants";
import CropModal from "./CropModal";

export type UploadFilesProps = {
  multiple?: boolean;
  value?: any;
  accept?: any;
  uploadUrl?: string;
  children?: ReactNode;
  onChange?: (value: any) => void;
  nbCols?: number;
  label?: string;
  maxSize?: number;
  max?: number;
  maxWidth?: number;
  maxHeight?: number;
  keepRatio?: boolean;
  name?: string;
  onUpload?: (value: any) => void;
  "data-cy"?: any;
};

function ImageUploader({
  multiple = false,
  value = null,
  accept = "image/x-png,image/gif,image/jpeg",
  uploadUrl = `${Constants.API_URL}/upload`,
  children,
  onChange = () => {},
  nbCols = 3,
  label,
  maxSize = Infinity,
  max = Infinity,
  maxWidth = 1600,
  maxHeight = 1200,
  keepRatio = true,
  name = "",
  onUpload,
  "data-cy": dataCy,
  ...rest
}: UploadFilesProps) {
  const { t } = useTranslation();
  const inputEl: any = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [tmpFiles, setTmpFiles] = useState<any[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [files, setFiles] = useState([]);

  const handleFileSelected = useCallback(
    async ({
      target: { files: inputFiles },
    }: ChangeEvent<HTMLInputElement>) => {
      if (
        !inputFiles ||
        !inputFiles.length ||
        files.length + inputFiles.length > max
      ) {
        return;
      }

      const aspect = keepRatio ? maxWidth / maxHeight : undefined;
      const fileslist = [];

      for (let i = 0; i < inputFiles.length; i++) {
        const maxIndex = Math.max(
          ...fileslist.map((currentFile) => currentFile.index)
        );

        const currentInputFile = inputFiles?.item(i);

        const file: any = {
          index: fileslist.length ? maxIndex + 1 : 0,
          inputFile: currentInputFile,
          name: currentInputFile?.name,
          crop: {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            unit: "px",
            aspect,
          },
          url: URL.createObjectURL(currentInputFile as Blob),
        };

        if (file.inputFile.size <= maxSize) {
          fileslist.push(file);
        }
      }

      setTmpFiles(fileslist);
      setShowModal(true);
    },
    [files, setTmpFiles, keepRatio, maxWidth, maxHeight, max, maxSize]
  );

  const onRemoveFile = useCallback(
    (removeFile: any) => {
      /* const file = files.find(f => f.name === removeFile.name);
   file.isToRemove = true;
   const newFiles = [...files]; */
      const newFiles = files.filter((f: any) => f.hash !== removeFile.hash);
      onChange(multiple ? newFiles : newFiles.length > 0 ? newFiles[0] : null);
    },
    [multiple, files, onChange]
  );

  const sendFile = useCallback(
    async (imageResult: any) => {
      const token = await getStoredData("token");
      const response = await fetch((imageResult as any).url);
      const file = await response.blob();

      return new Promise((resolve) => {
        const req = new XMLHttpRequest();

        req.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            setUploadingFiles((prevFiles: any) => ({
              ...prevFiles,
              [imageResult.name]: {
                ...file,
                state: "pending",
                percentage: (event.loaded / event.total) * 100,
              },
            }));
          }
        });

        req.upload.addEventListener("load", () => {
          setUploadingFiles((prevFiles: any) => ({
            ...prevFiles,
            [imageResult.name]: {
              ...file,
              state: "done",
              percentage: 100,
            },
          }));
        });

        req.upload.addEventListener("error", () => {
          setUploadingFiles((prevFiles: any) => ({
            ...prevFiles,
            [imageResult.name]: {
              ...prevFiles[imageResult.name],
              state: "error",
              percentage: 0,
            },
          }));
          resolve(null);
        });

        // eslint-disable-next-line func-names
        req.addEventListener("load", function () {
          try {
            // eslint-disable-next-line react/no-this-in-sfc
            const res = JSON.parse(this.response);

            if (onUpload) {
              onUpload(res);
            }

            resolve({
              ...res[0],
              state: "done",
              percentage: 100,
              filename: res.filename,
            });
          } catch (e) {
            resolve({
              ...file,
              state: "error",
              percentage: 0,
              filename: "titi",
            });
          }
        });

        const formData = new FormData();

        formData.append("files", imageResult.file);
        req.open("POST", uploadUrl);
        req.setRequestHeader("Authorization", `Bearer ${token}`);
        req.send(formData);
      });
    },
    [onUpload, uploadUrl]
  );

  const handleCloseModal = useCallback(() => {
    if (inputEl?.current?.value) {
      inputEl.current.value = "";
      setTmpFiles([]);
    }
    setShowModal(false);
  }, [setTmpFiles, setShowModal]);

  const handleConfirmModal = useCallback(async () => {
    setShowModal(false);

    const filesToUpload: any = {};

    let file: any;

    for (file of tmpFiles) {
      const fileObj = await getCroppedImg({
        image: file.image,
        imageUrl: file.url,
        fileType: file.inputFile.type,
        crop: file.crop,
        fileName: file.inputFile.name,
        maxWidth,
        maxHeight,
      });

      filesToUpload[file.name] = {
        name: file.name,
        url: URL.createObjectURL(fileObj as Blob),
        file: fileObj,
        type: file.inputFile.type,
        crop: file.crop,
        state: "pending",
        percentage: 0,
      };
    }

    setUploadingFiles(filesToUpload);

    const promiseFiles: any = [];

    Object.values(filesToUpload).forEach((f) => {
      promiseFiles.push(sendFile(f));
    });
    const uploadedFiles: any = await Promise.all(promiseFiles);

    if (multiple) {
      const newFiles: any = [...files];

      uploadedFiles.forEach((f: any) => {
        if (f) newFiles.push(f);
      });
      setFiles(newFiles);
      onChange(newFiles);
    } else {
      setFiles(uploadedFiles);
      onChange(uploadedFiles[0]);
    }

    setUploadingFiles({});
  }, [tmpFiles, multiple, maxWidth, maxHeight, sendFile, files, onChange]);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newFiles: any = arrayMoveImmutable(files, oldIndex, newIndex);

    newFiles[0].updated_at = new Date();
    setFiles(newFiles);
    onChange([...newFiles]);
  };

  const ufiles: any = Object.values(uploadingFiles) ?? [];
  const fileslist: any = [...files, ...ufiles];

  useEffect(() => {
    if (value) {
      setFiles(multiple ? value : [value]);
    } else {
      setFiles([]);
    }
  }, [value, setFiles, multiple]);

  if (multiple) {
    return (
      <div {...rest}>
        <CropModal
          confirmModal={handleConfirmModal}
          closeModal={handleCloseModal}
          tmpFiles={tmpFiles}
          setTmpFiles={setTmpFiles}
          visible={showModal}
        />
        <div className="multiple-image-uploader">
          {!fileslist[0] && (
            <label className="input input-image-uploader">
              {label || t("uploader.uploadImages")}
            </label>
          )}
          <input
            name={name}
            ref={inputEl}
            data-cy={dataCy}
            type="file"
            accept={accept}
            className="file-upload"
            onChange={handleFileSelected}
            multiple
          />
          <DefaultFilesList
            axis="xy"
            nbCols={nbCols}
            ratio={maxHeight / maxWidth}
            onSortEnd={onSortEnd}
            items={fileslist}
            onRemoveFile={onRemoveFile}
          />
        </div>
      </div>
    );
  }

  const styleBackground: any = {
    backgroundImage: fileslist[0]
      ? `url(${Constants.ROOT_URL}${fileslist[0].url})`
      : "",
  };
  const classNames = `input single-image-uploader rounded-lg ${
    fileslist[0] ? "image-load" : ""
  } bg-cover bg-center relative w-[250px] h-[250px] md:w-[180px] md:h-[180px]`;
  // border-2 drop-shadow-sm bg-white rounded-md bg-contain bg-center relative text-center
  return (
    <div {...rest}>
      <CropModal
        confirmModal={handleConfirmModal}
        closeModal={handleCloseModal}
        tmpFiles={tmpFiles}
        setTmpFiles={setTmpFiles}
        visible={showModal}
      />
      <div className={classNames} style={styleBackground}>
        <div className="flex flex-col text-xs items-center justify-center h-full w-full cursor-pointer text-slate-500 hover:text-green-500 rounded-lg">
          {!fileslist[0] && (
            <div
              onClick={() => {
                inputEl.current.click();
              }}
              className="flex flex-col text-xs items-center justify-center h-full w-full border border-dashed cursor-pointer text-slate-500 border-slate-300 hover:text-green-500 hover:border-green-400 rounded-lg"
            >
              <PlusCircleIcon className="w-6 h-6" />
              {label || t("forms.uploadPicture")}
            </div>
          )}
          {ufiles[0] && ufiles[0].percentage !== 100 && (
            <div className="ProgressBar h-2 bg-zinc-700 rounded absolute w-9/10 left-0 right-0 my-0 mx-auto top-0">
              <div
                className="Progress bg-orange-500 h-full m-0 rounded"
                style={{ width: `${ufiles[0].percentage}%` }}
              />
            </div>
          )}
          {files.length > 0 && (
            <Button
              onClick={() => {
                onRemoveFile(files[0]);
              }}
              icon="TrashIcon"
              className="absolute bottom-1 right-1"
              type="error"
              compact
            />
          )}
          <input
            name={name}
            ref={inputEl}
            type="file"
            accept={accept}
            className="file-upload hidden"
            onChange={handleFileSelected}
            multiple={multiple}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageUploader;
