import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import useItem from "common/hooks/useItem";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import HeaderButtons from "common/components/DetailHeader/HeaderButtons";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import PageLoading from "common/components/PageLoading/PageLoading";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import Icon from "components/Icon/Icon";
import Status from "common/components/Status/Status";
import AlertsContext from "common/providers/alerts";
import { Content } from "types/Content";
import formatPrice from "utils/formatPrice";
import fetchJSON from "common/utils/fetchJSON";
import useFields from "hooks/useFields";
import Modal from "common/components/Modal/Modal";
import UserCard from "components/UserCard/UserCard";
import { Gallery, Item } from "react-photoswipe-gallery";
import rdvSecuriseImg from "../../../public/images/handshake-light.png";
import { ExtraFieldItem } from "./ExtraFieldItem";
import "photoswipe/dist/photoswipe.css";

function ContentDetails() {
  const router = useRouter();
  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const [fullScreenImg, setFullScreenImg] = useState<any | null>(null);

  const { item, fetchItem, isFetching, isUpdateFetching, error }: any = useItem(
    "contents",
    id,
    {
      populate: [
        "category",
        "category.logo",
        "author",
        "author.avatar",
        "author.companyLogo",
        "images",
        "extraFields",
      ],
    }
  );

  const {
    getFieldsByFieldNames,
    fields,
    isFetching: fieldsIsFetching,
  } = useFields();

  const fieldsList = useMemo(() => {
    if (item?.extraFields && fields && !fieldsIsFetching) {
      return getFieldsByFieldNames(
        Object.keys(item.extraFields),
        item.category
      );
    }
    return [];
  }, [item, fields, fieldsIsFetching, getFieldsByFieldNames]);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const {
    title,
    description,
    publishedDate,
    endPublishedDate,
    price,
    images,
    status,
    author,
    category,
    address,
    updatedAt,
    useSafeMeetingPlace,
    canExchange,
    isChapChap,
  } = (item as Content) || {};

  const canSubmit = ["draft", "rejected"].includes(status);
  const isPendingModeration = status === "submitted";
  const canUnpublish = ["published"].includes(status);
  const canArchive = !["submitted", "sold", "closed"].includes(status);

  const contentAction = useCallback(
    async (action: string) => {
      const res = await fetchJSON({
        method: "POST",
        url: `contents/data/${action}/${id}`,
      });
      if (res) {
        fetchItem();
      }
    },
    [fetchItem, id]
  );

  const handleSubmit = useCallback(() => {
    contentAction("submit");
  }, [contentAction]);

  const handlePublish = useCallback(() => {
    contentAction("publish");
  }, [contentAction]);

  const handleUnpublish = useCallback(() => {
    contentAction("unpublish");
  }, [contentAction]);

  const handleReject = useCallback(() => {
    contentAction("reject");
  }, [contentAction]);

  const handleArchive = useCallback(() => {
    contentAction("archive");
  }, [contentAction]);

  const PublicationHeaderInfos = useMemo(
    () => (
      <div className="w-full">
        <div className="flex items-center text-xs gap-1 field-title">
          <Icon name="ClockIcon" className="w-4 h-4" />
          {dayjs(updatedAt).fromNow()}
        </div>
        <div className="responsive-flex items-center gap-3">
          <div className="flex flex-row items-center text-ternary-800">
            <div className="text-sm flex">
              <div className="flex items-center">
                <span>
                  {endPublishedDate
                    ? t("reports.publishedFromTo", {
                        from: dayjs(publishedDate).format("LL"),
                        to: dayjs(endPublishedDate).format("LL"),
                      })
                    : t("forms.notPublishedYet")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    [endPublishedDate, publishedDate, t, updatedAt]
  );

  const PublicationHeaderSubTitle = useCallback(
    (mainText: string, subText?: string) => (
      <>
        <span className="font-bold">{mainText}</span>
        {subText && <span> | {subText}</span>}
      </>
    ),
    []
  );

  const getIndexRelativeImage = useCallback(
    (currentImage: any, indexVariation: number) => {
      let iImg = 0;
      images.forEach((image, i) => {
        if (image.url === currentImage?.url) {
          iImg = i;
        }
      });
      if (iImg + indexVariation < 0) {
        return images[images.length - 1];
      }
      if (iImg + indexVariation >= images.length) {
        return images[0];
      }
      return images[iImg + indexVariation];
    },
    [images]
  );

  if (isFetching && !item) {
    return <PageLoading />;
  }

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <Modal
        title={fullScreenImg?.name}
        visible={!!fullScreenImg}
        onCloseModal={() => setFullScreenImg(null)}
        showCloseModalFooter
        maxWindowSize
      >
        <div className="flex flex-row gap-0 justify-center items-stretch">
          <div
            className="flex flex-col justify-center cursor-pointer text-slate-600 hover:text-black hover:pt-1"
            onClick={() =>
              setFullScreenImg(getIndexRelativeImage(fullScreenImg, -1))
            }
          >
            <Icon name="ArrowLeftIcon" className="w-12 h-12" />
          </div>
          <div className="rounded-lg overflow-hidden opacity-100 bg-white">
            <ImageComponent image={ImageFromStrapiMedia(fullScreenImg)?.uri} />
          </div>
          <div
            className="flex flex-col justify-center cursor-pointer text-slate-600 hover:text-black hover:pt-1"
            onClick={() =>
              setFullScreenImg(getIndexRelativeImage(fullScreenImg, 1))
            }
          >
            <Icon name="ArrowRightIcon" className="w-12 h-12" />
          </div>
        </div>
      </Modal>
      <DetailHeader
        backLink="/contents"
        title={title}
        Status={
          <div className="flex gap-2 flex-wrap">
            <Status status={status} />
          </div>
        }
        subtitleIcon="WorkSiteIcon"
        TitleIcon={
          <div>
            <ImageComponent
              image={
                category?.logo
                  ? ImageFromStrapiMedia(category.logo)?.uri
                  : undefined
              }
            />
          </div>
        }
        HeaderInfos={PublicationHeaderInfos}
        SubTitle={PublicationHeaderSubTitle(category?.name)}
        HeaderButtons={
          <HeaderButtons
            editUrl={`/contents/edit/${id}`}
            // submit
            onSubmit={handleSubmit}
            submitDisabled={!canSubmit}
            // validate or reject
            onValidate={handlePublish}
            validateDisabled={!isPendingModeration}
            onReject={handleReject}
            rejectDisabled={!isPendingModeration}
            // unpublish
            onUnpublish={handleUnpublish}
            unpublishDisabled={!canUnpublish}
            // archive
            onArchive={handleArchive}
            archiveDisabled={!canArchive}
            // TODO : ne sert plus à rien puisque géré par des routes customs (!=update)
            // Il faudrait intégrer les fetchJSON dans un provider pour gérer les chargements de changement de statut
            isPublishing={isUpdateFetching("updateStatus")}
          />
        }
      />

      <div className="responsive-flex gap-2 mt-2">
        <div className="flex-1">
          <div className="flex flex-col gap-2 w-full">
            {(status === "draft" || status === "submitted") &&
              publishedDate && (
                <div className="w-full py-2 px-1">
                  <div className="px-4 py-2 w-full flex-col font-semibold text-md leading-4 bg-white text-slate-800 border border-slate-300 rounded-md shadow-sm ring-2 ring-offset-slate-50 ring-pink-500 ring-offset-0">
                    <div className="flex flex-row items-center">
                      <Icon
                        name="ExclamationTriangleIcon"
                        className="w-4 h-4 mr-1"
                      />
                      <span className="font-bold">
                        {t("infos.alreadyPublished", {
                          date: dayjs(publishedDate).format("LLL"),
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            {/* Description */}
            <div className="responsive-flex flex flex-col lg:flex-row gap-4 items-start">
              {/* Column 1 */}
              <div className="white-box responsive-flex gap-2 flex-col w-full lg:basis-1/2">
                <div className="flex flex-row gap-4">
                  <div className="basis-1/2">
                    <SectionTitle
                      title={t("forms.price")}
                      icon="WalletIcon"
                      className="text-secondary mt-2"
                    />
                    <div className="responsive-flex flex-row">
                      <span className="font-bold text-xl py-1 pl-4">
                        {formatPrice(price)}
                      </span>
                    </div>
                  </div>
                  <div className="basis-1/2 text-center">
                    <div className="flex flex-row justify-end gap-4 text-[#504e70]">
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          useSafeMeetingPlace
                            ? " shadow-md"
                            : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <Icon name="CheckBadgeSolid" className="w-9 h-9" />
                        </div>
                        RDV sécurisé
                      </div>
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          canExchange ? " shadow-md" : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <ImageComponent image={rdvSecuriseImg} />
                        </div>
                        Troc accepté
                      </div>
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          isChapChap ? " shadow-md" : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <Icon name="FireIcon" className="w-9 h-9" />
                        </div>
                        Chap chap !
                      </div>
                    </div>
                  </div>
                </div>
                <SectionTitle
                  title={t("forms.description")}
                  icon="BookmarkIcon"
                  className="text-secondary mt-2"
                />
                <div className="responsive-flex py-2 px-4 flex-col border rounded">
                  {description ? (
                    description.split("\n").map((p: any, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <p key={`p-${index}`} className="mb-4">
                        {p}
                      </p>
                    ))
                  ) : (
                    <p>{t("forms.noContent")}</p>
                  )}
                </div>
                <SectionTitle
                  title={t("forms.address")}
                  icon="MapPinIcon"
                  className="text-secondary mt-2"
                />
                <div className="responsive-flex flex-row">
                  <span className="text-md py-1 pl-4">{address}</span>
                </div>
              </div>
              {/* Column 2 */}
              <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
                <UserCard user={author} title={t("forms.author")} />
              </div>
            </div>
            {/* Images */}
            <div className="responsive-flex white-box">
              <div className="responsive-flex gap-4 flex-col w-full">
                <div className="responsive-flex gap-4 w-full flex-row flex-wrap justify-center">
                  <Gallery withDownloadButton>
                    {images?.map((file) => {
                      // const thumbnailUrl = ImageFromStrapiMedia(
                      //   file.formats.thumbnail
                      // ).uri;
                      const originalUrl = ImageFromStrapiMedia(file).uri;

                      return (
                        <Item
                          original={originalUrl}
                          thumbnail={originalUrl}
                          width={file.width}
                          height={file.height}
                          key={file.id}
                        >
                          {({ ref, open }) => (
                            <img
                              alt={file.name}
                              ref={
                                ref as unknown as React.MutableRefObject<HTMLImageElement>
                              }
                              onClick={open}
                              src={originalUrl}
                              className="rounded-lg cursor-pointer object-cover hover:shadow-lg h-[300px] w-[300px]"
                            />
                          )}
                        </Item>
                      );
                    })}
                  </Gallery>
                </div>
              </div>
            </div>

            {/* Extra fields & Map : alert & events */}
            {item?.type?.value !== "info" && (
              <div className="white-box responsive-flex justify-between items-start mt-2">
                {/* Extra fields */}
                <div className="flex w-full flex-col justify-start pr-6 gap-4">
                  <div className="flex flex-col gap-4">
                    <SectionTitle
                      title={t("forms.infos")}
                      icon="BsListCheck"
                      className="text-secondary"
                    />
                    <div className="flex w-full flex-col gap-2">
                      {/* {extraFieldsList.map((field: any) => (
                      <PublicationField {...field} />
                    ))} */}
                      {fieldsList?.map((f) => (
                        <ExtraFieldItem
                          field={f}
                          value={item.extraFields?.[f.name]}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentDetails;
