import {
  BuildingStorefrontIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  NewspaperIcon,
  TagIcon,
  UserIcon,
  UsersIcon,
  StarIcon,
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { MdOutlineCategory, MdCurrencyExchange } from "react-icons/md";

import SupportIcon from "components/Icon/icons/SupportIcon";
import { IconNames } from "components/Icon/Icon";

export type IMenuChild = {
  id: string;
  label: string;
  Icon: any;
  disableBoard?: boolean;
  add?: boolean;
  addRightType?: string;
  addRightKey?: string;
  addRightKeyJoker?: string[];
  useWordingPlural?: boolean;
  isReservedForJuloa?: boolean;
};
export type IMenuGroup = {
  label: string;
  children: IMenuChild[];
  icon: IconNames;
  useWordingPlural?: boolean;
  isReservedForJuloa?: boolean;
};

export type IMenuConf = Record<string, IMenuGroup>;

/** ******************************** Sections ******************************* */

const followSection: IMenuConf = {
  // suivi
  suivi: {
    label: "monitoring",
    icon: "MdOutlineChecklist",
    children: [
      {
        id: "dashboard",
        label: "board",
        Icon: HomeIcon,
        disableBoard: true,
      },
      {
        id: "emergencies",
        label: "emergencies",
        Icon: ExclamationCircleIcon,
        add: false,
        addRightKeyJoker: ["isAdmin"],
      },
      {
        id: "reports",
        label: "reports",
        Icon: ExclamationTriangleIcon,
        add: false,
        addRightKeyJoker: ["isAdmin"],
      },
      {
        id: "user-verifications",
        label: "userVerifications",
        Icon: CheckBadgeIcon,
        add: false,
        addRightKeyJoker: ["isAdmin"],
      },
    ],
  },
};

const managementSection: IMenuConf = {
  gestion: {
    label: "manage",
    icon: "MdOutlineChecklist",
    children: [
      {
        id: "contents",
        label: "contents",
        Icon: NewspaperIcon,
        add: true,
        addRightKeyJoker: ["isAdmin"],
      },
      {
        id: "transactions",
        label: "transactions",
        Icon: MdCurrencyExchange,
        add: false,
        addRightKeyJoker: ["isAdmin"],
      },
      {
        id: "users",
        label: "users",
        Icon: UsersIcon,
        add: true,
        addRightKeyJoker: ["isAdmin"],
      },
      {
        id: "reviews",
        label: "reviews",
        Icon: StarIcon,
        add: false,
        addRightKeyJoker: ["isAdmin"],
      },
    ],
  },
};

const mySpaceSection: IMenuChild[] = [
  {
    id: "my-account",
    label: "myAccount",
    Icon: UserIcon,
  },
  /* {
    id: "support",
    label: "support",
    Icon: SupportIcon,
  }, */
];

// const usersSection = {
//   id: "users",
//   label: "users",
//   Icon: UsersIcon,
//   add: true,
//   addRightType: "role",
//   addRightKey: "isAdmin",
// };

const adminUsersSection: IMenuChild[] = [
  {
    id: "categories",
    label: "categories",
    Icon: MdOutlineCategory,
    add: true,
    addRightKeyJoker: ["isAdmin"],
  },
  {
    id: "fields",
    label: "fields",
    Icon: TagIcon,
    add: true,
    addRightKeyJoker: ["isAdmin"],
    isReservedForJuloa: false,
  },
  {
    id: "partners",
    label: "partners",
    Icon: BuildingStorefrontIcon,
    add: true,
    addRightKeyJoker: ["isAdmin"],
  },
];

/** **********************************  Menus by roles ************************** */

export const proMenu: IMenuConf = {
  ...followSection,
  ...managementSection,
  reglages: {
    label: "params",
    icon: "IoSettingsOutline",
    children: [...mySpaceSection],
  },
};

export const adminMenu: IMenuConf = {
  ...followSection,
  ...managementSection,
  reglages: {
    label: "params",
    icon: "IoSettingsOutline",
    children: [...adminUsersSection, ...mySpaceSection],
  },
};
