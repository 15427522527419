import { Link } from "react-router-dom";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";

import Button from "common/components/Button/Button";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import { Category } from "types/Category";
import ImageComponent from "common/components/ImageComponent/ImageComponent";

const containerStyle =
  "flex px-4 py-2 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all overflow-hidden";

function CategoryItem({ item, onDelete, onNavigate }: any) {
  const {
    name,
    order,
    logo,
    subCategories = [],
    isExchangeAvailable,
    isSafeMeetingPlaceAvailable,
  } = item as Category;

  const { t } = useTranslation();

  return (
    <div
      className={`${containerStyle}  items-start lg:items-stretch flex-col lg:flex-row min-h-28`}
    >
      <div className="w-full">
        <div className="responsive-flex-lg h-full items-center mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4">
          <div className="flex flex-col justify-center overflow-hidden">
            <h4 className="text-xl font-bold text-primary border border-primary rounded-full leading-6 w-8 h-8 text-center py-1">
              {order}
            </h4>
          </div>
          <div className="flex relative w-full lg:w-16 xl:w-24 shrink-0">
            <div className="">
              <ImageComponent
                image={logo ? ImageFromStrapiMedia(logo)?.uri : undefined}
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div className="flex flex-col xl:flex-row w-full gap-4">
              <div className="flex flex-row justify-between flex-1">
                <h4 className="text-xl font-bold text-primary leading-6">
                  {name}
                </h4>
                {subCategories.length > 0 ? (
                  <h4 className="text-lgfont-bold text-primary leading-6">
                    {t("forms.nbSubCategories", {
                      count: subCategories.length,
                    })}
                  </h4>
                ) : null}
              </div>
            </div>

            <div className="responsive-flex-lg mt-1" />

            {/* foot */}
            {subCategories.length > 0 ? (
              <div className="flex flex-col gap-2 pt-1 mt-2">
                {subCategories
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((sub) => (
                    <CategoryItem
                      item={sub}
                      onDelete={onDelete}
                      onNavigate={onNavigate}
                    />
                  ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* right */}
      <div className="flex flex-col justify-between text-xs w-full lg:border-l lg:pl-4 lg:w-[30em] lg:border-slate-150 gap-2">
        <div className="flex w-full justify-between text-slate-500">
          <div className="flex items-center gap-3">
            {isExchangeAvailable ? (
              <div className="rounded border">TROC</div>
            ) : null}
            {isSafeMeetingPlaceAvailable ? (
              <div className="rounded border">RDVS</div>
            ) : null}
          </div>
        </div>

        <div className="flex w-full gap-1 justify-end">
          <Button
            type="error-line"
            icon="TrashIcon"
            compact
            onClick={() => onDelete(item.id)}
            confirmMessage={t("actions.confirmDelete")}
          />

          <Button
            title={t("actions.edit")}
            type="warning-line"
            icon="PencilSquareIcon"
            onClick={() => onNavigate(item.id)}
          />
        </div>
      </div>
    </div>
  );
}

export default CategoryItem;
