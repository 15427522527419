import useAuth from "common/hooks/useAuth";
import { getStoredData } from "common/utils/fnAsyncStorage";
import { Constants } from "constants/Constants";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import io, { Socket } from "socket.io-client";

type Props = {
  children?: React.ReactNode;
};

const SocketContext = createContext<object | null>(null);

export type Listener = {
  events: string[];
  handleEvent: (notificationString: string, update: object) => void;
};

let listeners = [] as Listener[];

export const addEventListener = (listener: Listener) => {
  listeners.push(listener);
};
export const removeEventListener = (listener: Listener) => {
  listeners = listeners.filter((l) => l !== listener);
};

export function SocketProvider({ children }: Props) {
  const [socket, setSocket] = useState<Socket>();
  const { user } = useAuth();

  const authenticate = useCallback(async () => {
    const token = await getStoredData("token");
    if (socket) {
      socket.emit("authenticate", { token });
    }
  }, [socket]);

  // init socket
  useEffect(() => {
    setSocket(io(Constants.ROOT_URL));
  }, []);

  // setAlert not available the first time, so we have to reset events callback
  useEffect(() => {
    if (socket) {
      socket.off("Notification");

      socket.on("Notification", (eventType: string, data: object) => {
        listeners
          .filter((l) => l.events.includes(eventType))
          .forEach((listener) => {
            listener.handleEvent(eventType, data);
          });
      });
      socket.on("reconnect", async () => {
        authenticate();
      });
    }
  }, [user, authenticate, socket]);

  // if user is logged in, authenticate socket
  useEffect(() => {
    if (user) {
      authenticate();
    }
  }, [user, authenticate]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <SocketContext.Provider value={{}}>{children}</SocketContext.Provider>;
}

const useSocket = () => useContext(SocketContext);

export default useSocket;
