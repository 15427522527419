import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import fetchJSON from "common/utils/fetchJSON";
import { ReportNumber } from "types/Report";

type ReportProviderProps = {
  reportsNumber: ReportNumber;
  refreshReportsNumber: () => Promise<ReportNumber>;
  isFetching: boolean;
};

export const ReportContext = createContext<ReportProviderProps>(
  {} as ReportProviderProps
);

type Props = {
  children: React.ReactNode;
};

const defaultReportsNumber: ReportNumber = {
  all: 0,
  user: 0,
  content: 0,
  transaction: 0,
};

export function ReportProvider({ children }: Props) {
  const [isFetching, setIsFetching] = useState(false);
  const [reportsNumber, setReportsNumber] =
    useState<ReportNumber>(defaultReportsNumber);

  const refreshReportsNumber = useCallback(async () => {
    try {
      setIsFetching(true);

      const res = await fetchJSON({
        url: `reports/data/number`,
        method: "GET",
      });

      if (res) {
        setReportsNumber(
          res
            ? {
                ...res,
              }
            : undefined
        );
      } else {
        setReportsNumber(defaultReportsNumber);
      }

      return res ?? false;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      setIsFetching(false);
    }
  }, [setReportsNumber]);

  useEffect(() => {
    const firstLoad = async () => {
      await refreshReportsNumber();
    };

    firstLoad();
  }, [refreshReportsNumber]);

  const value: ReportProviderProps = useMemo(
    () => ({
      reportsNumber,
      refreshReportsNumber,
      isFetching,
    }),
    [reportsNumber, isFetching, refreshReportsNumber]
  );

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
}
