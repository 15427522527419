import { useTranslation } from "react-i18next";
import Select from "common/components/Select/Select";

function RoleSelect(props: any) {
  const { t } = useTranslation();

  const { disabled } = props;

  const options = [
    { value: "Authenticated", label: t("roles.Authenticated") },
    { value: "Administrateur", label: t("roles.Administrateur") },
    { value: "SuperAdmin", label: t("roles.SuperAdmin") },
  ];

  return (
    <Select options={options} icon="StarIcon" disabled={disabled} {...props} />
  );
}

export default RoleSelect;
