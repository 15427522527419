function LoginHeader() {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        className="mx-auto w-[80%]"
        src="/images/main-logo.png"
        alt="Toulouse-eco Logo"
      />
    </div>
  );
}

export default LoginHeader;
