import dayjs from "dayjs";
import { Link } from "react-router-dom";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";

import Status from "common/components/Status/Status";
import Button from "common/components/Button/Button";
import truncateString from "common/utils/truncateString";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import { Content } from "types/Content";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import Icon from "components/Icon/Icon";
import getFlattenText from "common/utils/getFlattenText";
import useAuth from "common/hooks/useAuth";
import formatPrice from "utils/formatPrice";
import { FiChevronsRight } from "react-icons/fi";
import TooltipWrapper from "common/components/TooltipWrapper/TooltipWrapper";
import UserBadges from "components/UserBadges/UserBadges";

const containerStyle =
  "flex px-4 py-4 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all overflow-hidden";

function ContentItem({ item, onDelete, onNavigate }: any) {
  const {
    id,
    title,
    author,
    description,
    publishedDate,
    endPublishedDate,
    price,
    images,
    status,
    canExchange,
    useSafeMeetingPlace,
    isChapChap,
    address,
    updatedAt,
  } = item as Content;

  const { t } = useTranslation();
  const { user } = useAuth();

  const detailLink = `/contents/${id}`;

  return (
    <div
      className={`${containerStyle}  items-start lg:items-stretch flex-col lg:flex-row`}
    >
      <Link to={detailLink} className="w-full">
        <div className="responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4">
          <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
            <div className="absolute -top-2 -left-2 w-full h-full rounded-lg overflow-hidden">
              <ImageComponent
                image={
                  images?.[0]
                    ? ImageFromStrapiMedia(images?.[0])?.uri
                    : undefined
                }
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div className="flex flex-row w-full gap-4 flex-1 justify-between">
              <div className="flex flex-col flex-1">
                <h4 className="text-xl font-bold text-primary leading-6">
                  {title}
                </h4>
              </div>
              <h4 className="text-md text-slate-500 leading-6 flex flex-row justify-start items-center font-normal text-sm">
                <Icon name="ClockIcon" className="w-4 h-4 mr-1" />
                {dayjs(updatedAt).fromNow()}
              </h4>
            </div>

            <div className="responsive-flex-lg flex-row mt-1">
              <div className="flex flex-row basis-1/2">
                <h4 className="font-bold text-md text-slate-500 leading-6 flex flex-row items-center">
                  <Icon name="UserIcon" className="w-4 h-4 mr-1" />
                  <TooltipWrapper
                    className="mr-1 my-1"
                    content={<span>{author?.email}</span>}
                    place="top"
                  >
                    {author?.username}
                  </TooltipWrapper>
                </h4>
                <UserBadges user={author} classname="px-2" noText isSmall />
              </div>
              <div className="flex flex-row justify-end basis-1/2 gap-4 font-normal">
                {address && (
                  <h4 className="text-md text-slate-500 leading-6 flex flex-row flex-1 items-center">
                    <Icon name="MapPinIcon" className="w-4 h-4 mr-1" />
                    {address}
                  </h4>
                )}
              </div>
            </div>

            <div className="responsive-flex-lg flex-row mt-1">
              <div className="text-sm flex basis-1/2">
                <div className="flex items-center mr-4">
                  <span className="font-bold text-lg text-secondary">
                    {formatPrice(price)}
                  </span>
                </div>
              </div>
              <div className="flex flex-row basis-1/2 items-center text-ternary-800">
                <div className="text-sm flex">
                  <div className="flex items-center">
                    <Icon name="ClockIcon" className="w-4 h-4 mr-1" />
                    <span>
                      {endPublishedDate
                        ? dayjs(publishedDate).format("LL")
                        : t("forms.notPublishedYet")}
                    </span>
                  </div>
                </div>
                {endPublishedDate && (
                  <>
                    <div className="py-1 mx-1">
                      <FiChevronsRight className="w-3 h-3 text-slate-500" />
                    </div>
                    <div className="text-sm flex">
                      <div className="flex items-center">
                        <span>{dayjs(endPublishedDate).format("LL")}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="responsive-flex-lg mt-1" />

            {/* foot */}
            <div className="flex flex-col gap-2 pt-1 border-t border-slate-200">
              {description && (
                <div className="text-sm text-gray-500 pt-2">
                  {truncateString(getFlattenText(description), 150)}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>

      {/* right */}
      <div className="flex flex-col justify-between text-xs w-full lg:border-l lg:pl-4 lg:w-[30em] lg:border-slate-150 gap-2">
        <div className="flex w-full justify-between text-slate-500">
          {status ? <Status status={status} /> : <div />}

          <div className="flex items-center gap-3">
            {canExchange ? <div className="rounded border">TROC</div> : null}
            {useSafeMeetingPlace ? (
              <div className="rounded border">RDVS</div>
            ) : null}
            {isChapChap ? <div className="rounded border">ChapChap</div> : null}
          </div>
        </div>

        {user?.isSuperAdmin && (
          <div className="flex w-full gap-1 justify-end">
            <Button
              type="error-line"
              icon="TrashIcon"
              compact
              onClick={() => onDelete(item?.id)}
              confirmMessage={t("actions.confirmDelete")}
            />

            {user?.isJuloaAdmin && (
              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item?.id)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ContentItem;
