import { IoDocumentOutline } from "react-icons/io5";

interface IImageComponent {
  image?: string;
}
function ImageComponent({ image }: IImageComponent) {
  if (image && !image.includes("undefined")) {
    if (
      image.endsWith(".png") ||
      image.endsWith(".jpg") ||
      image.endsWith(".jpeg") ||
      image.endsWith(".webp")
    ) {
      return (
        <img
          src={image}
          className="object-cover object-center h-full w-full"
          alt=""
        />
      );
    }
    return (
      <div className="flex flex-col items-center justify-center h-full w-full border border-slate-200 rounded-lg p-1 overflow-hidden">
        <IoDocumentOutline className="text-6xl" />
        <div className="text-center text-wrap w-full text-ellipsis">
          {image.match(/[^/]+$/)?.[0]}
        </div>
      </div>
    );
  }

  return (
    <img
      src="/images/fallback.jpg"
      className="object-cover object-center h-full w-full"
      alt=""
    />
  );
}

export default ImageComponent;
