import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import { Field } from "types/Field";
import CategorySelect from "components/select/CategorySelect/CategorySelect";

type FieldFormProps = {
  field: Field | undefined;
  onSubmit: (values: any) => any;
};

function FieldForm({ onSubmit, field }: FieldFormProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();

  const initialValues = useMemo(
    () =>
      field?.id
        ? {
            ...field,
            categories: field.categories?.map(({ id }) => id) || [],
          }
        : {
            name: "",
            label: "",
          },
    [field]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([f]: any, state: any, utils: any) => {
          utils.changeValue(state, f.field, () => f.value);
        },
      }}
      render={({ handleSubmit, values }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(field?.id ? "editItem.field" : "newItem.field")}
              subTitle={values.name}
              icon="HandThumbUpIconSolid"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/fields")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex gap-2 mt-2">
                <div className="md:w-2/3 flex flex-col gap-2">
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="label"
                      title={t("common.label")}
                      required
                    />
                    <FormControl
                      name="name"
                      titleKey={t("forms.name")}
                      required
                    />
                    <FormControl
                      name="type"
                      type="buttongroup"
                      titleKey={t("forms.type")}
                      required
                      options={[
                        { value: "text", label: "Texte" },
                        { value: "numeric", label: "Numérique" },
                        { value: "boolean", label: "Booléen" },
                      ]}
                    />
                    <FormControl
                      name="description"
                      titleKey={t("forms.description")}
                    />
                    <FormControl
                      name="placeholder"
                      titleKey={t("forms.placeholder")}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:w-1/3 gap-2">
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="categories"
                      titleKey={t("forms.categories")}
                      type="custom"
                      multiple
                    >
                      <CategorySelect />
                    </FormControl>
                    <FormControl
                      name="order"
                      titleKey={t("forms.order")}
                      type="numeric"
                    />
                  </div>
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="required"
                      titleKey={t("forms.required")}
                      type="switch"
                    />
                    <FormControl
                      name="searchable"
                      titleKey={t("forms.searchable")}
                      type="switch"
                    />
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/fields")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default FieldForm;
