import { Link } from "react-router-dom";

import Button from "common/components/Button/Button";
import { useTranslation } from "react-i18next";

interface IHeaderButtons {
  onDelete?: () => void;
  deleteDisabled?: boolean;
  editUrl?: string;
  editDisabled?: boolean;
  // submit
  onSubmit?: () => void;
  submitDisabled?: boolean;
  // validate and reject
  onValidate?: () => void;
  onReject?: () => void;
  validateDisabled?: boolean;
  rejectDisabled?: boolean;
  // unbublish
  onUnpublish?: () => void;
  unpublishDisabled?: boolean;
  // archive
  onArchive?: () => void;
  archiveDisabled?: boolean;
  isPublishing?: boolean;
}

function HeaderButtons({
  onDelete,
  deleteDisabled,
  editUrl,
  editDisabled,
  onSubmit,
  submitDisabled,
  onValidate,
  onReject,
  validateDisabled,
  rejectDisabled,
  onUnpublish,
  unpublishDisabled,
  onArchive,
  archiveDisabled,
  isPublishing,
}: IHeaderButtons) {
  const { t } = useTranslation();
  return (
    <div className="responsive-flex-lg gap-1 lg:justify-end w-full mt-3 lg:mt-0 items-start lg:items-center">
      {onSubmit && !submitDisabled && (
        <Button
          title={t("actions.submit")}
          type="purple-line"
          icon="BsSendCheck"
          onClick={onSubmit}
          loading={isPublishing}
          // compact
          disabled={submitDisabled}
        />
      )}
      {/* modo actions */}
      {onDelete && !deleteDisabled ? (
        <Button
          onClick={onDelete}
          type="error-line"
          icon="TrashIcon"
          // compact
          confirm
          title={t("actions.delete")}
        />
      ) : null}
      {onValidate && !validateDisabled && (
        <Button
          title={t("actions.validate")}
          type="valid"
          icon="CheckIcon"
          onClick={onValidate}
          loading={isPublishing}
          disabled={validateDisabled}
        />
      )}
      {onReject && !rejectDisabled && (
        <Button
          title={t("actions.reject")}
          type="error"
          icon="XMarkIcon"
          onClick={onReject}
          loading={isPublishing}
          disabled={rejectDisabled}
        />
      )}
      {onUnpublish && !unpublishDisabled && (
        <Button
          title={t("actions.unpublish")}
          type="error-line"
          icon="MdOutlineUnpublished"
          onClick={onUnpublish}
          loading={isPublishing}
          disabled={unpublishDisabled}
        />
      )}
      {onArchive && !archiveDisabled && (
        <Button
          title={t("actions.close")}
          type={
            !submitDisabled || !validateDisabled || !unpublishDisabled
              ? "neutral-line"
              : "neutral"
          }
          icon="IoArchiveOutline"
          onClick={onArchive}
          confirm
          loading={isPublishing}
          disabled={archiveDisabled}
        />
      )}

      {/* writers actions */}
      {editUrl && !editDisabled && (
        <Link to={editUrl} className="flex w-full lg:w-auto">
          <Button
            title={t("actions.edit")}
            type="warning-line"
            icon="PencilSquareIcon"
          />
        </Link>
      )}
    </div>
  );
}

export default HeaderButtons;
