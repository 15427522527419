const fixFinalFormEmptyValues = (initialValues: any, values: any) => {
  const newValues = { ...values };
  // for each property from type that is not present add it with value null
  Object.keys(initialValues).forEach((key) => {
    if (!Object.keys(newValues).includes(key)) {
      // eslint-disable-next-line no-param-reassign
      newValues[key] = Array.isArray(initialValues[key]) ? [] : null;
    }
  });

  return newValues;
};

export default fixFinalFormEmptyValues;
