/* eslint-disable react/no-array-index-key */
import DebounceInput from "common/components/DebounceInput/DebounceInput";
import { HTMLAttributes, ClassAttributes, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePagination, useSortBy, useTable } from "react-table";
import "react-activity/dist/library.css";
import Pagination from "./Pagination";
import PageLoading from "../PageLoading/PageLoading";
import StickyWrapper from "../StickyWrapper/StickyWrapper";
// import Loader from '../Loader/Loader';

type Props = {
  data: any[];
  columns: any[];
  isFetching: boolean;
  pageCount: number;
  itemsPerPage?: number;
  itemsCount?: number;
  filters: { [key: string]: any };
  setFilter: (filter: any, value: any) => void;
  setPageIndex: (pageIndex: number) => void;
  pageIndex: number;
  RenderItem: any;
  onDelete: any;
  onNavigate?: any;
  onDuplicate?: any;
  onSynchronize?: any;
  noHeader?: boolean;
};

function FilteredList({
  data,
  columns,
  isFetching,
  pageCount: controlledPageCount,
  itemsCount = 0,
  itemsPerPage = 0,
  filters,
  setFilter,
  pageIndex,
  setPageIndex,
  RenderItem,
  onDelete,
  onNavigate,
  onDuplicate,
  onSynchronize,
  noHeader = false,
}: Props) {
  const { t } = useTranslation();
  const {
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex: currentPageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      initialState: { pageIndex },
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (pageIndex !== currentPageIndex) {
      setPageIndex(currentPageIndex);
    }
  }, [currentPageIndex, pageIndex, setPageIndex]);

  const handleChangeFilter = useCallback(
    (name: any, value: any) => {
      // TODO ca va faire deux fetchData, mais super compliqué a optimiser,
      // il faut un debounce mais aussi modifier le fetchData pour que la fonction ne change jamais
      gotoPage(0);
      setFilter(name, value);
    },
    [gotoPage, setFilter]
  );

  return (
    <div className="flex flex-col relative min-h-[300px]">
      {!noHeader && (
        <StickyWrapper isFilters disabledOnMobile>
          <div
            className=" flex w-full 
      justify-center px-4 py-3 border border-t-0 border-slate-150 rounded-b-lg mb-2 z-10 bg-slate-100"
          >
            {headerGroups.map(
              (
                headerGroup: {
                  getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                    ClassAttributes<HTMLTableRowElement> &
                    HTMLAttributes<HTMLTableRowElement>;
                  headers: any[];
                },
                index: any
              ) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  key={`header-${index}`}
                  className="w-full flex flex-col gap-4 lg:flex-row lg:flex-wrap align-center"
                >
                  {headerGroup.headers.map((column: any, key: any) =>
                    column.hidden ? null : (
                      <div
                        key={`column-${key}`}
                        className={`py-0 flex flex-col ${column.columnClasses} maxLg:w-full`}
                      >
                        {/* <span
                          className={`text-xxs text-secondary uppercase ${
                            column.headerClasses || "mb-1"
                          }`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <i className="ml fas fa-sort-down" />
                            ) : (
                              <i className="ml fas fa-sort-up" />
                            ))}
                            </span> */}
                        {column.filterName &&
                          (column.filterComponent ? (
                            <column.filterComponent
                              placeholder={column.render("Header")}
                              value={filters[column.filterName]}
                              data-cy={column.filterName}
                              onChange={(value: any) => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                column.customFormat
                                  ? handleChangeFilter(
                                      column.filterName,
                                      column.customFormat(value)
                                    )
                                  : handleChangeFilter(
                                      column.filterName,
                                      value
                                    );
                              }}
                            />
                          ) : (
                            <DebounceInput
                              className="text-input-filter"
                              // placeholder={column.filterLabel}
                              placeholder={column.render("Header")}
                              value={filters[column.filterName]}
                              onChange={(value) =>
                                handleChangeFilter(column.filterName, value)
                              }
                            />
                          ))}
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </div>
        </StickyWrapper>
      )}

      <div className="w-full relative">
        {!isFetching && !page.length && (
          <span className="table-no-result">{t("common.noResult")}</span>
        )}
        {page.map((row: any) => {
          prepareRow(row);
          return (
            <RenderItem
              item={row.original}
              onDelete={onDelete}
              onNavigate={onNavigate}
              onDuplicate={onDuplicate}
              onSynchronize={onSynchronize}
            />
          );
        })}

        {page.length > 0 && controlledPageCount !== 1 && (
          <StickyWrapper isFooter>
            <Pagination
              goToPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              totalPage={controlledPageCount}
              disabled={isFetching}
              // new
              pageIndex={pageIndex}
              itemsPerPage={itemsPerPage}
              itemsCount={itemsCount}
            />
          </StickyWrapper>
        )}
      </div>

      {isFetching === true && (
        <div className="w-full fixed left-72 max-main-box top-thirty-vh">
          <PageLoading customStyles="absolute top-[75px] left-[calc(50%-100px)] w-[200px] rounded-full h-16 mx-auto flex justify-center items-center bg-slate-100/80 z-10" />
        </div>
      )}
    </div>
  );
}

export default FilteredList;
