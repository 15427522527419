import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import { Transaction } from "types/Transaction";
import Select from "common/components/Select/Select";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import formatPrice from "utils/formatPrice";
import dayjs from "dayjs";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import Icon from "components/Icon/Icon";
import UserCard from "components/UserCard/UserCard";
import leadZeros from "utils/leadZeros";
import rdvSecuriseImg from "../../../public/images/handshake-light.png";

type TransactionFormProps = {
  transaction: Transaction | undefined;
  onSubmit: (values: any) => any;
};

function TransactionForm({ onSubmit, transaction }: TransactionFormProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();

  const initialValues = useMemo(
    () =>
      transaction?.id
        ? {
            ...transaction,
          }
        : {
            name: "",
            label: "",
          },
    [transaction]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  const {
    content,
    buyer,
    seller,
    // blocked,
    // finalExchangeOffer,
    finalMeetingPlace,
    finalPrice,
    startMeetingDate,
    endMeetingDate,
  } = (transaction as Transaction) || {};

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([f]: any, state: any, utils: any) => {
          utils.changeValue(state, f.field, () => f.value);
        },
      }}
      render={({ handleSubmit, values }) => {
        function setFullScreenImg(image: string) {
          throw new Error("Function not implemented.");
        }

        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(
                transaction?.id ? "editItem.transaction" : "newItem.transaction"
              )}
              subTitle={t("titleLegends.transaction", {
                id: leadZeros(values.id),
              })}
              icon="MdCurrencyExchange"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/transactions")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex gap-2 mt-2">
                <div className="white-box responsive-flex flex-col justify-between w-full lg:basis-1/2">
                  <SectionTitle
                    title={t("common.status")}
                    icon="Status"
                    className="text-secondary"
                  />
                  <FormControl name="status" type="custom" className="mb-4">
                    <Select
                      options={[
                        "opened",
                        "price_waiting_answer",
                        "price_denied",
                        "price_validated",
                        "meeting_waiting_answer",
                        "meeting_denied",
                        "meeting_validated",
                        "on_place_both",
                        "transaction_waiting_validation",
                        "transaction_validated",
                        "reviewed_both",
                        "cancelled",
                      ].map((name) => ({
                        value: name,
                        label: t(`status.${name}`),
                      }))}
                    />
                  </FormControl>
                  <div className="flex flex-col gap-1">
                    <SectionTitle
                      title={t("forms.priceValidated")}
                      icon="WalletIcon"
                      className="text-secondary"
                    />
                    <div className="responsive-flex flex-row">
                      <span className="font-bold text-xl pl-4">
                        {formatPrice(finalPrice)}
                      </span>
                    </div>
                    <SectionTitle
                      title={t("forms.meetingTime")}
                      icon="BookmarkIcon"
                      className="text-secondary mt-2"
                    />
                    <div className="responsive-flex flex-row">
                      <span className="font-bold text-xl py-1 pl-4">
                        {startMeetingDate && endMeetingDate
                          ? `Le ${dayjs(startMeetingDate).format(
                              "LL"
                            )} de ${dayjs(startMeetingDate).format(
                              "H:mm"
                            )} à ${dayjs(endMeetingDate).format("H:mm")}`
                          : t("transactions.noMeetingDate")}
                      </span>
                    </div>
                    <SectionTitle
                      title={t("forms.meetingPlace")}
                      icon="BookmarkIcon"
                      className="text-secondary mt-2"
                    />
                    {finalMeetingPlace ? (
                      <div className="responsive-flex flex-col">
                        {finalMeetingPlace.addressTitle && (
                          <div className="responsive-flex flex-row">
                            <span className="font-bold text-xl py-1 pl-4">
                              {finalMeetingPlace.addressTitle}
                            </span>
                          </div>
                        )}
                        {finalMeetingPlace.street && (
                          <div className="responsive-flex flex-row">
                            <span className="font-medium text-xl pl-4">
                              {`${finalMeetingPlace.street}`}
                            </span>
                          </div>
                        )}
                        {finalMeetingPlace.postalCode &&
                          finalMeetingPlace.city && (
                            <div className="responsive-flex flex-row">
                              <span className="font-medium text-xl pl-4">
                                {`${finalMeetingPlace.postalCode} ${finalMeetingPlace.city}`}
                              </span>
                            </div>
                          )}
                      </div>
                    ) : (
                      <div className="responsive-flex flex-row">
                        <span className="font-bold text-xl py-1 pl-4">
                          {t("transactions.noMeetingPlace")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="responsive-flex gap-2 flex-col w-full lg:basis-1/2">
                  <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
                    <UserCard user={buyer} title={t("forms.buyer")} />
                  </div>
                  <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
                    <UserCard user={seller} title={t("forms.seller")} />
                  </div>
                </div>
              </div>
              <div className="responsive-flex gap-2 mt-2">
                <div className="flex-1">
                  <div className="flex flex-col gap-2 w-full">
                    {/* Description */}
                    <div className="responsive-flex flex flex-col lg:flex-row gap-4 items-start">
                      {/* Column 1 */}
                      <div className="white-box responsive-flex gap-2 flex-col w-full">
                        <div className="flex flex-row gap-4">
                          <div className="basis-1/3">
                            <SectionTitle
                              title={t("forms.title")}
                              icon="WalletIcon"
                              className="text-secondary mt-2"
                            />
                            <div className="responsive-flex flex-row">
                              <span className="font-bold text-xl py-1 pl-4">
                                {content.title}
                              </span>
                            </div>
                          </div>
                          <div className="basis-1/3">
                            <SectionTitle
                              title={t("forms.price")}
                              icon="WalletIcon"
                              className="text-secondary mt-2"
                            />
                            <div className="responsive-flex flex-row">
                              <span className="font-bold text-xl py-1 pl-4">
                                {formatPrice(content.price)}
                              </span>
                            </div>
                          </div>
                          <div className="basis-1/3 text-center">
                            <div className="flex flex-row justify-end gap-4 text-[#504e70]">
                              <div
                                className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                                  content.useSafeMeetingPlace
                                    ? " shadow-md"
                                    : "opacity-20 shadow-xs"
                                }`}
                              >
                                <div className="max-h-9 max-w-9">
                                  <Icon
                                    name="CheckBadgeSolid"
                                    className="w-9 h-9"
                                  />
                                </div>
                                RDV sécurisé
                              </div>
                              <div
                                className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                                  content.canExchange
                                    ? " shadow-md"
                                    : "opacity-20 shadow-xs"
                                }`}
                              >
                                <div className="max-h-9 max-w-9">
                                  <ImageComponent image={rdvSecuriseImg} />
                                </div>
                                Troc accepté
                              </div>
                              <div
                                className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                                  content.isChapChap
                                    ? " shadow-md"
                                    : "opacity-20 shadow-xs"
                                }`}
                              >
                                <div className="max-h-9 max-w-9">
                                  <Icon name="FireIcon" className="w-9 h-9" />
                                </div>
                                Chap chap !
                              </div>
                            </div>
                          </div>
                        </div>
                        <SectionTitle
                          title={t("forms.description")}
                          icon="BookmarkIcon"
                          className="text-secondary mt-2"
                        />
                        <div className="responsive-flex py-2 px-4 flex-col border rounded">
                          {content.description ? (
                            content.description
                              .split("\n")
                              .map((p: any, index: number) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <p key={`p-${index}`} className="mb-4">
                                  {p}
                                </p>
                              ))
                          ) : (
                            <p>{t("forms.noContent")}</p>
                          )}
                        </div>
                        <SectionTitle
                          title={t("forms.address")}
                          icon="MapPinIcon"
                          className="text-secondary mt-2"
                        />
                        <div className="responsive-flex flex-row">
                          <span className="text-md py-1 pl-4">
                            {content.address}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Images */}
                    <div className="responsive-flex white-box">
                      <div className="responsive-flex gap-4 flex-col w-full">
                        <div className="responsive-flex gap-4 w-full flex-row flex-wrap justify-center">
                          {content.images?.map((image, index) => (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={`image-${index}`}
                              className="rounded-lg overflow-hidden aspect-square h-[250px] md:h-[180px]"
                              onClick={() => {
                                setFullScreenImg(image);
                              }}
                            >
                              <ImageComponent
                                image={ImageFromStrapiMedia(image)?.uri}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/transactions")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default TransactionForm;
