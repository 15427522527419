/* eslint-disable no-console */
import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import fetchJSON from "common/utils/fetchJSON";
import { ContentsNumber } from "types/Content";

type ContentProviderProps = {
  contentsNumber: ContentsNumber;
  refreshContentsNumber: () => Promise<ContentsNumber>;
  isFetching: boolean;
};

export const ContentContext = createContext<ContentProviderProps>(
  {} as ContentProviderProps
);

type Props = {
  children: React.ReactNode;
};

const defaultContentsNumber: ContentsNumber = {
  all: 0,
  draft: 0,
  submitted: 0,
  published: 0,
  rejected: 0,
  negociated: 0,
  sold: 0,
  closed: 0,
};

export function ContentProvider({ children }: Props) {
  const [isFetching, setIsFetching] = useState(false);
  const [contentsNumber, setContentsNumber] = useState<ContentsNumber>(
    defaultContentsNumber
  );

  const refreshContentsNumber = useCallback(async () => {
    try {
      setIsFetching(true);

      const res = await fetchJSON({
        url: `contents/data/number`,
        method: "GET",
      });

      if (res) {
        setContentsNumber(
          res
            ? {
                ...res,
              }
            : undefined
        );
      } else {
        setContentsNumber(defaultContentsNumber);
      }

      return res ?? false;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      setIsFetching(false);
    }
  }, [setContentsNumber]);

  useEffect(() => {
    const firstLoad = async () => {
      await refreshContentsNumber();
    };

    firstLoad();
  }, [refreshContentsNumber]);

  const value: ContentProviderProps = useMemo(
    () => ({
      contentsNumber,
      refreshContentsNumber,
      isFetching,
    }),
    [contentsNumber, isFetching, refreshContentsNumber]
  );

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
}
