import { useCallback, useEffect, useState } from "react";
import CancellablePromise from "common/utils/CancellablePromise";
import { parseStrapiFormat } from "common/utils/strapi";
import qs from "qs";
import {
  addEventListener,
  Listener,
  removeEventListener,
} from "../providers/SocketProvider";
import useFetch, { UseFetchOptions } from "./useFetch";

// const postExecute = (data: { results: any }): any => data.results;

export type UseItemOptions = {
  populate?: string | object;
  fields?: string | object;
  listenToEvents?: string[];
} & UseFetchOptions;

export type UseItemType<T> = {
  isFetching: boolean;
  item: T | null;
  error: string | null;
  isPreviousItem: boolean;
  fetch: () => CancellablePromise<unknown>;
  fetchItem: (id: any) => CancellablePromise<unknown>;
  saveItem: (
    payload: any,
    useDataPayload?: boolean
  ) => CancellablePromise<unknown>;
  updateItem: (
    id: any,
    payload: any,
    useDataPayload?: boolean,
    actionName?: string
  ) => Promise<unknown>;
  removeItem: (id: any) => CancellablePromise<unknown>;
  isUpdateFetching: (actionName: string) => boolean;
};

const useItem = <T,>(
  url: string,
  itemId: number | string | null,
  options: UseItemOptions = {}
): UseItemType<T> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { populate, fields, listenToEvents, ...opts } = options;
  const [fetchings, setFetchings] = useState<string[]>([]);

  const addFetching = (name: string) =>
    setFetchings((previousFetchings: string[]) => [...previousFetchings, name]);
  const removeFetching = (name: string) =>
    setFetchings((previousFetchings: string[]) =>
      previousFetchings.filter((n) => n !== name)
    );
  const isUpdateFetching = (name: string) =>
    fetchings.find((n) => n === name) != null;

  const getUrl = useCallback(
    (url_orig: string) => {
      const query = {} as {
        populate?: string | object;
        fields?: string | object;
      };

      if (populate) {
        query.populate = populate;
      }
      if (fields) {
        query.fields = fields;
      }

      const queryParams = qs.stringify(query, {
        encodeValuesOnly: true,
      });

      return `${url_orig}${queryParams ? "?" : ""}${queryParams}`;
    },
    [fields, populate]
  );

  const getDetailUrl = getUrl(`${url}/${itemId}`);
  const getSimpleUrl = getUrl(url);

  opts.postExecute = parseStrapiFormat;

  const { fetchData, data, error, isFetching, isPreviousData, setData } =
    useFetch<T>(getSimpleUrl, {
      sharePromise: false,
      manual: true,
      // postExecute,
      ...opts,
    });

  const fetch = useCallback(
    () => fetchData({ url: getSimpleUrl, method: "GET" }),
    [fetchData, getSimpleUrl]
  );

  const fetchItem = useCallback(
    () => fetchData({ url: getDetailUrl, method: "GET" }),
    [fetchData, getDetailUrl]
  );

  const saveItem = useCallback(
    (payload: any, useDataPayload = true) => {
      const p = useDataPayload ? { data: payload } : { ...payload };
      return fetchData({ method: "POST", payload: p });
    },
    [fetchData]
  );

  const removeItem = useCallback(
    (id: any) => fetchData({ url: getUrl(`${url}/${id}`), method: "DELETE" }),
    [fetchData, getUrl, url]
  );

  const updateItem = useCallback(
    async (
      id: any,
      payload: any,
      useDataPayload = true,
      actionName = "update"
    ) => {
      const p = useDataPayload ? { data: payload } : { ...payload };
      addFetching(actionName);
      const result = await fetchData({
        url: getUrl(`${url}/${id}`),
        method: "PUT",
        payload: p,
      });
      removeFetching(actionName);
      return result;
    },
    [fetchData, getUrl, url]
  );

  useEffect(() => {
    setData(null);
    if (itemId) {
      fetchItem();
    }
  }, [fetchItem, setData, itemId]);

  useEffect(() => {
    if (listenToEvents && listenToEvents.length > 0) {
      const listener: Listener = {
        events: listenToEvents,
        handleEvent: () => {
          fetchItem();
        },
      };
      addEventListener(listener);

      return () => {
        removeEventListener(listener);
      };
    }
    return () => {};
  }, [listenToEvents, fetchItem, itemId]);

  return {
    isFetching,
    item: data,
    error,
    isPreviousItem: isPreviousData,
    fetch,
    fetchItem,
    saveItem,
    updateItem,
    removeItem,
    isUpdateFetching,
  };
};

export default useItem;
