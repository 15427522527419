import useAuth from "common/hooks/useAuth";
import useItem from "common/hooks/useItem";

import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import UserForm from "./UserForm";

function MyAccountForm() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const { user: currentUser } = useAuth();
  const router = useRouter();

  const {
    saveItem,
    updateItem,
    item: myUser,
    error,
    isFetching,
  }: any = useItem("users", currentUser?.id || 0, {
    populate: ["role", "avatar"],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const onSubmit = useCallback(
    async (dataUser: any) => {
      const user = { ...dataUser };

      try {
        if (user.id) {
          await updateItem(user.id, user, false);
        } else {
          await saveItem(user, false);
        }
      } catch (e: any) {
        return e;
      }

      if (!error) {
        setAlert(t("common.saved"), "success");
        router.navigate("/");
      }
      return true;
    },
    [updateItem, saveItem, setAlert, error, t, router]
  );

  return isFetching && currentUser && !myUser ? null : (
    <UserForm onSubmit={onSubmit} editedUser={myUser} myAccount />
  );
}

export default MyAccountForm;
