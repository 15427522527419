import { EnvelopeIcon } from "@heroicons/react/24/outline";
import Button from "common/components/Button/Button";
import { Link } from "react-router-dom";
import { User } from "types";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import Status from "common/components/Status/Status";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import UserAvatar from "components/UserAvatar/UserAvatar";
import UserBadges from "components/UserBadges/UserBadges";
import dayjs from "dayjs";

function UserItem({ item, onDelete, onNavigate }: any) {
  const { user: currentUser } = useAuth();

  const {
    username,
    lastname,
    firstname,
    email,
    role,
    id,
    isCompany,
    companyName,
    nbExchange,
    nbSafeMeetingPlace,
    nbChapchap,
    createdAt,
  } = item as User;
  const canEditItem =
    (role.name !== "SuperAdmin" || currentUser?.isSuperAdmin) &&
    currentUser?.id !== id;

  const editLink = `/users/edit/${item.id}`;

  const { t } = useTranslation();

  return (
    <div className="responsive-flex-lg px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem
            ? "pointer-events-none responsive-flex-lg flex-1"
            : "responsive-flex-lg flex-1"
        }
      >
        <div className="flex relative w-40 h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
          <UserAvatar user={item} />
        </div>
        <div className="flex flex-col flex-1 justify-between lg:pl-4 lg:pr-4">
          <div className="flex h-full gap-3">
            <div className="grid w-full gap-1 pt-3 lg:py-0">
              <div className="flex items-center gap-1 mb-1 text-xl">
                <span className="font-bold text-primary leading-4">
                  {username}
                </span>
                {" -  "}
                <span className="leading-4">{firstname}</span>
                <span className="leading-4">{lastname}</span>
                <div className="text-xs text-right flex-1 px-1 pt-0 pb-4">
                  {t("forms.subscribedThe", {
                    since: dayjs(createdAt).format("DD/MM/YYYY"),
                  })}
                </div>
              </div>

              <div className="grid grid-flow-col items-center ">
                {isCompany && (
                  <div className="flex items-center gap-1 text-sm text-primary">
                    <HiOutlineBuildingOffice2 className="w-4 h-4" />
                    <span className="text-md font-bold">{companyName}</span>
                  </div>
                )}
                <UserBadges user={item} classname="justify-self-end" />
              </div>
              <div className="flex flex-row w-full items-center justify-between mt-1 self-end place-self-end justify-self-end">
                <div className="flex items-center gap-1">
                  {email && (
                    <>
                      <EnvelopeIcon className="w-3 h-3" />
                      <span className="font-bold">{email}</span>
                    </>
                  )}
                </div>
                <div className="flex items-center gap-1 text-primary text-xs">
                  <span className="rounded-full border b-primary px-2 py-1">{`${t(
                    "forms.exchange"
                  )} : ${nbExchange || 0}`}</span>
                  <span className="rounded-full border b-primary px-2 py-1">{`${t(
                    "forms.safeMeetingPlace"
                  )} : ${nbSafeMeetingPlace || 0}`}</span>
                  <span className="rounded-full border b-primary px-2 py-1">{`${t(
                    "forms.chapchap"
                  )} : ${nbChapchap || 0}`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-between items-end text-xs lg:border-l pl-4 min-w-[24em] border-slate-150 gap-2">
        <div className={role ? "flex justify-end gap-2" : ""}>
          {role && <Status status={role?.name} />}
          <Status status={item.blocked ? "blocked" : "active"} />
        </div>

        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />
              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserItem;
