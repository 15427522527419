import { Gallery } from "react-photoswipe-gallery";
import ThreadGalleryItem from "./ThreadGalleryItem";
import "photoswipe/dist/photoswipe.css";

interface IThreadGallery {
  documents?: any;
  isPrivate?: boolean;
  onRemoveFile?: any;
  editMode?: boolean;
  small?: boolean;
}
function ThreadGallery({
  documents,
  isPrivate,
  onRemoveFile,
  editMode,
  small = false,
}: IThreadGallery) {
  const containerStyle = isPrivate
    ? "flex gap-1"
    : small
    ? "flex gap-1 justify-end"
    : "flex gap-1";
  const list = (documents || []).map((file: any) => (
    <ThreadGalleryItem
      file={file}
      onRemoveFile={() => onRemoveFile(file)}
      editMode={editMode}
      small={small}
    />
  ));

  if (!documents || documents.length < 1) {
    return null;
  }
  return (
    <Gallery withDownloadButton>
      <div className={containerStyle}>{list}</div>
    </Gallery>
  );
}

export default ThreadGallery;
