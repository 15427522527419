import useList from "common/hooks/useList";
import fetchJSON from "common/utils/fetchJSON";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Report } from "types/Report";

type EmergencyProviderProps = {
  emergencies: Report[] | null;
  fetchEmergencies: any;
  isFetchingEmergencies: boolean;
  isEmergencyAlert: boolean;
};

export const EmergencyContext = createContext<EmergencyProviderProps>(
  {} as EmergencyProviderProps
);

type Props = {
  children: React.ReactNode;
};

export function EmergencyProvider({ children }: Props) {
  const [isEmergencyAlert, setIsEmergencyAlert] = useState<boolean>(false);
  const {
    items: emergencies,
    fetchItems: fetchEmergencies,
    isFetching: isFetchingEmergencies,
  } = useList<Report>("reports", {
    listenToEvents: ["new_emergency", "emergency_processed"],
    defaultFilters: {
      isEmergency: true,
    },
    defaultSorts: ["createdAt:DESC"],
    populate: [
      "user",
      "user.avatar",
      "user.companyLogo",
      "author",
      "author.avatar",
      "author.companyLogo",
      "content",
      "content.author",
      "content.author.avatar",
      "content.author.companyLogo",
      "content.images",
    ],
  });

  const refreshIsEmergencyAlert = useCallback(async () => {
    try {
      const res = await fetchJSON({
        url: `reports/emergency/unprocessed`,
        method: "GET",
      });

      if (res) {
        setIsEmergencyAlert(true);
      } else {
        setIsEmergencyAlert(false);
      }

      return res ?? false;
    } catch (e) {
      setIsEmergencyAlert(false);
      return false;
    }
  }, [setIsEmergencyAlert]);

  useEffect(() => {
    const firstLoad = async () => {
      await refreshIsEmergencyAlert();
    };

    if (emergencies) {
      firstLoad();
    }
  }, [refreshIsEmergencyAlert, emergencies]);

  const value: EmergencyProviderProps = useMemo(
    () => ({
      emergencies,
      fetchEmergencies,
      isFetchingEmergencies,
      isEmergencyAlert,
    }),
    [emergencies, fetchEmergencies, isFetchingEmergencies, isEmergencyAlert]
  );

  return (
    <EmergencyContext.Provider value={value}>
      {children}
    </EmergencyContext.Provider>
  );
}
