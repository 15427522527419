import { Item } from "react-photoswipe-gallery";

import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import FileIcon from "common/components/FileUploader/FileIcon";
import Icon from "components/Icon/Icon";
import { Constants } from "constants/Constants";

const imageExt = [".jpg", ".jpeg", ".png", ".gif", ".webp"];
function ThreadGalleryContent({ file, small }: any) {
  const { state, percentage, isToRemove } = file;
  const size = small ? "w-8 h-8" : "w-36 h-36";

  if (isToRemove) return <div style={{ width: 0 }} />;

  if (state === "pending") {
    // Percentage always 0 then 100 ?
    return (
      <div className="classes.fileProgressBar">
        <div
          className="classes.fileProgress"
          style={{ width: `${percentage}%` }}
        />
      </div>
    );
  }

  if (imageExt.includes(file.ext) && file.formats) {
    const thumbnailUrl = ImageFromStrapiMedia(file.formats.thumbnail)?.uri;
    const originalUrl = ImageFromStrapiMedia(file)?.uri;

    return (
      <Item
        original={originalUrl}
        thumbnail={thumbnailUrl}
        width={file.width}
        height={file.height}
        key={file.id}
      >
        {({ ref, open }) => (
          <img
            alt={file.name}
            ref={ref as unknown as React.MutableRefObject<HTMLImageElement>}
            onClick={open}
            src={originalUrl}
            className={`rounded-lg cursor-pointer object-cover hover:shadow-lg ${size}`}
          />
        )}
      </Item>
    );
  }
  return (
    <a
      href={`${Constants.ROOT_URL}${file.url}`}
      target="_blank"
      className={`group flex flex-col items-center justify-center relative bg-slate-200 rounded-lg ${size} cursor-pointer`}
      rel="noreferrer"
    >
      <div className="absolute flex items-center justify-center inset-0 bg-slate-700/30 transition-all opacity-0 group-hover:opacity-100 rounded-lg">
        <Icon name="BiDownload" className="w-4 h-4 text-white" />
      </div>
      <FileIcon ext={file.ext} />
      {!small && (
        <div className="text-xs truncate px-3 mt-1 w-full">{file.name}</div>
      )}
    </a>
  );
}

interface IThreadGalleryItem {
  file: any;
  onRemoveFile?: any;
  editMode?: boolean;
  small?: boolean;
}
function ThreadGalleryItem({
  file,
  onRemoveFile,
  editMode,
  small,
}: IThreadGalleryItem) {
  return (
    <div className="flex relative group">
      {editMode && (
        <div
          className={`flex items-center justify-center absolute z-20 bg-secondary text-secondary-50 rounded-full opacity-0 group-hover:opacity-100 cursor-pointer hover:bg-secondary-600 ${
            small ? "w-4 h-4 -top-1 -right-1" : "w-8 h-8 top-2 right-2"
          }`}
          onClick={onRemoveFile}
        >
          <Icon name="XMarkIcon" className="w-3 h-3" />
        </div>
      )}
      <ThreadGalleryContent file={file} small={small} />
    </div>
  );
}

export default ThreadGalleryItem;
