import { useTranslation } from "react-i18next";
import { Field } from "types/Field";

export function ExtraFieldItem({ field, value }: { field: Field; value: any }) {
  const { t } = useTranslation();
  return (
    <div key={field.name} className="w-full flex flex-row gap-2">
      <div className="text-md">
        <strong>{field.label}</strong>
      </div>
      :
      <div className="flex-1">
        {field.type === "boolean"
          ? value
            ? t("common.yes")
            : t("common.no")
          : value}
      </div>
    </div>
  );
}

export default ExtraFieldItem;
