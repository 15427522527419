import { Link } from "react-router-dom";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";

import Button from "common/components/Button/Button";
import truncateString from "common/utils/truncateString";
import Icon from "components/Icon/Icon";
import getFlattenText from "common/utils/getFlattenText";
import useAuth from "common/hooks/useAuth";
import TooltipWrapper from "common/components/TooltipWrapper/TooltipWrapper";
import { Review } from "types/Review";
import dayjs from "dayjs";
import { useMemo } from "react";
import Status from "common/components/Status/Status";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { StarIcon } from "@heroicons/react/20/solid";

const containerStyle =
  "flex px-2 py-2 mb-2 w-full relative cursor-pointer rounded-lg border transition-all overflow-hidden";

function ReviewItem({ item, onDelete }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const detailLink = `/reviews/${item.id}`;

  return (
    <div
      className={`${containerStyle} ${"bg-white border-slate-300"} items-start lg:items-stretch flex-col lg:flex-row`}
    >
      <Link to={detailLink} className="w-full">
        <div
          className={`responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4 `}
        >
          <div className="flex relative shrink-0 mb-2 mr-4">
            <UserAvatar
              user={item.author}
              forcedSize="w-16 h-16"
              forcedCompanyLogoSize="w-8 h-8"
              growOnSmallDevice={false}
            />
          </div>
          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div className="font-bold flex flex-row w-full items-center gap-4 flex-wrap text-primary">
              <h4>
                A propos de :{" "}
                {item.onUser?.username ?? item.onMeetingPlace?.title}
              </h4>

              <div className="w-8 h-8 rounded-full overflow-hidden my-1">
                <ImageComponent
                  image={
                    item.onUser?.avatar
                      ? ImageFromStrapiMedia(item.onUser?.avatar).uri
                      : undefined
                  }
                />
              </div>
            </div>

            <div className="responsive-flex-lg mt-1" />

            {/* foot */}
            <div className="flex flex-row justify-start gap-2 items-center pt-2 border-t border-slate-200">
              <div className="flex flex-col">
                {!!item.comment && (
                  <div className="flex flex-row items-center gap-1 text-sm text-gray-500 font-bold">
                    <Icon name="ChatBubbleLeftRightIcon" className="w-4 h-4" />

                    {truncateString(getFlattenText(item.comment), 150)}
                  </div>
                )}

                <div className="flex flex-row items-center gap-1 text-sm text-gray-500">
                  <Icon name="UserIcon" className="w-4 h-4" />

                  {`Par ${item.author?.username}, le ${dayjs(
                    item.createdAt
                  ).format("DD/MM/YYYY")}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* right */}
      <div className="flex flex-col justify-between text-xs w-full lg:border-l lg:pl-4 lg:w-[30em] lg:border-slate-150 gap-2">
        <div className="flex flex-row justify-between gap-2 w-full">
          <div className="flex flex-row gap-1 items-center">
            <StarIcon width={25} className="text-yellow-500" />

            <p className="text-gray-600 leading-6 text-xl">
              <b className="text-gray-900">{item.ratings}</b>
              /5
            </p>
          </div>
          <div className="flex justify-between items-center text-slate-500">
            <Status status={item.type} />
          </div>
        </div>

        <div className="flex w-full gap-1 justify-end">
          <Button
            type={item?.isRemoved ? "danger" : "valid-line"}
            icon={item?.isRemoved ? "EyeSlashIcon" : "EyeIcon"}
            compact
            // onClick={() => onDelete(item.id, item.isRemoved)} // is not a real delete but cancel
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewItem;
