import { HomeIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import { IMenuChild, IMenuGroup, adminMenu, proMenu } from "conf/menu";
import Header from "common/components/Header/Header";
import useAuth from "common/hooks/useAuth";
import getWording from "common/utils/wording";
import classNames from "classnames";
import useEmergencies from "common/hooks/useEmergencies";
import SectionTitle from "../../common/components/SectionTitle/SectionTitle";
import Button from "../../common/components/Button/Button";
import useRouter from "../../common/hooks/use-router";
import checkRights from "../../common/utils/checkRights";

interface IDashboardItem {
  item: IMenuChild;
}

function DashboardItem({ item }: IDashboardItem) {
  const {
    label,
    id,
    Icon,
    add,
    addRightType,
    addRightKey,
    addRightKeyJoker,
    useWordingPlural,
  } = item;
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { user: currentUser } = useAuth();
  const { isEmergencyAlert } = useEmergencies();

  const onListClick = () => navigate(`../${id}`, { relative: "path" });
  const onAddClick = () => navigate(`../${id}/add`, { relative: "path" });

  const user = currentUser as any;
  const isEmergencies = id === "emergencies" && isEmergencyAlert;

  const canAdd = () => {
    if (!add) {
      return false;
    }
    if (addRightKey) {
      if (addRightType === "role") {
        return !!user[addRightKey];
      }
      if (addRightType === "jobPosition") {
        return checkRights(
          user,
          addRightKey,
          user?.mySitesId,
          addRightKeyJoker
        );
      }
      return true;
    }

    return true;
  };

  return (
    <div
      className={classNames(
        "relative flex items-center justify-between border  p-1 pl-3 rounded-lg ",
        isEmergencies
          ? "bg-red-200 text-slate-900 border-red-300 font-semibold"
          : "bg-slate-50 border-slate-200 text-slate-700"
      )}
    >
      <div className="flex gap-2 items-center">
        <Icon
          className={classNames(
            "w-4 h-4 ",
            isEmergencies ? "text-secondary" : "text-secondary"
          )}
        />
        {useWordingPlural
          ? t(`titlePages.${label}`, {
              wording: getWording(true, true),
            })
          : t(`titlePages.${label}`)}
      </div>
      <div className="flex gap-1">
        <Button
          type="primary-line"
          compact
          icon="MdList"
          onClick={onListClick}
        />
        <Button
          type="valid-line"
          compact
          icon="PlusCircleIcon"
          onClick={onAddClick}
          disabled={!canAdd()}
          className={`${!canAdd() ? "cursor-not-allowed" : ""} ${
            !add && "hidden"
          }`}
        />
      </div>

      {!!isEmergencies && (
        <span className="absolute top-1 right-1 flex h-3 w-3">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
        </span>
      )}
    </div>
  );
}

interface IDashboardGroup {
  group: IMenuGroup;
}

function DashboardGroup({ group }: IDashboardGroup) {
  const { label, children, icon } = group;
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const childrenList = children
    .filter((child) => !child.isReservedForJuloa || currentUser?.isJuloaAdmin)
    .filter((child) => !child.disableBoard)
    .map((child) => <DashboardItem item={child} />);

  return childrenList.length > 0 ? (
    <div className="flex flex-col gap-1 white-box">
      <SectionTitle
        title={t(`titleDividers.${label}`)}
        icon={icon}
        className="text-lg text-ternary"
      />
      {childrenList}
    </div>
  ) : null;
}

function Home() {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const activeMenu = currentUser?.isAdmin ? adminMenu : proMenu;

  const list = Object.values(activeMenu)
    .filter((group) => !group.isReservedForJuloa || currentUser?.isJuloaAdmin)
    .map((group) => <DashboardGroup group={group} />);

  return (
    <div className="py-6">
      <div className="mx-auto text-sm max-w-full max-main-box px-4 mb-4">
        <Header
          title={t("titlePages.board")}
          subTitle={t("titleLegends.board")}
          Icon={HomeIcon}
        />
        <div className="grid-1or2 gap-2 mt-4">{list}</div>
      </div>
    </div>
  );
}

export default Home;
