import Icon, { IconNames } from "components/Icon/Icon";

interface ISectionTitle {
  title: string;
  icon?: IconNames;
  className?: string;
  small?: boolean;
}

function SectionTitle({
  title,
  icon,
  className = "",
  small = false,
}: ISectionTitle) {
  return (
    <div
      className={`${
        small ? "small-section-title" : "section-title"
      } ${className}`}
    >
      {icon && <Icon name={icon} className="w-4 h-4" />}
      {title}
    </div>
  );
}

export default SectionTitle;
