import SectionTitle from "common/components/SectionTitle/SectionTitle";
import UserAvatar from "components/UserAvatar/UserAvatar";
import UserBadges from "components/UserBadges/UserBadges";
import { User } from "types";

function UserCard({ user, title = "User" }: { user: User; title?: string }) {
  return (
    <>
      <SectionTitle title={title} icon="UserIcon" className="text-ternary" />
      <div className="responsive-flex flex-row gap-8">
        <UserAvatar user={user} growOnSmallDevice={false} />
        <div className="grid flex-1">
          <div className="flex flex-row justify-between flex-wrap items-center flex-1">
            <div className="font-bold text-md">{user?.username}</div>
            <div className="text-md">{user?.email}</div>
          </div>
          <div className="flex flex-row justify-between flex-wrap items-center flex-1">
            <div className="font-bold text-md">
              {user?.firstname} {user?.lastname}
            </div>
            <div className="text-md">{user?.phone}</div>
          </div>
          <UserBadges user={user} classname="self-justify-end" />
        </div>
      </div>
    </>
  );
}

export default UserCard;
