import Icon from "components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { User } from "types";

function UserBadges({
  user,
  noText = false,
  isSmall = false,
  classname = "",
}: {
  user: User;
  noText?: boolean;
  isSmall?: boolean;
  classname?: string;
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`flex flex-row gap-2 items-center justify-start ${classname}`}
    >
      {user?.isCompany && (
        <div
          className={`flex items-center gap-1 font-normal leading-[0] text-center text-white bg-slate-500 rounded-full shrink-0 ${
            isSmall ? "px-1 py-0.5" : "px-2 py-1"
          }`}
        >
          <Icon
            name="IoFileTrayFullOutline"
            className={`${isSmall ? "w-4 h-4" : "w-5 h-5"}`}
          />
          {!noText && ` ${t("common.company")}`}
        </div>
      )}
      {user?.verified && (
        <div
          className={`flex items-center gap-1 font-normal leading-[0] text-center text-white bg-green-500 rounded-full shrink-0 ${
            isSmall ? "px-1 py-0.5" : "px-2 py-1"
          }`}
        >
          <Icon
            name="CheckIcon"
            className={`${isSmall ? "w-4 h-4" : "w-5 h-5"}`}
          />
          {!noText && ` ${t("common.verifiedProfile")}`}
        </div>
      )}
    </div>
  );
}

export default UserBadges;
