// import Loader from 'common/components/Loader/Loader';
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field as FieldType } from "types/Field";
import FieldForm from "./FieldForm";

function Field() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any =
    useItem<FieldType>("fields", id, {
      populate: { categories: { fields: ["id"] } },
    });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/fields");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (values: any) => {
      const newField = { ...values };

      try {
        if (newField.id) {
          await updateItem(newField.id, newField);
        } else {
          await saveItem(newField);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <FieldForm onSubmit={onSubmit} field={item} />
  );
}

export default Field;
