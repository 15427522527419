import { useMemo } from "react";
import { Route, Routes as RRoutes } from "react-router-dom";
import { useAuth } from "common/hooks/useAuth";
import Page404 from "./Page404";
import { loginRoutes } from "../routes";

const getUserRoutes = (routes: any, user: any) =>
  routes.map((route: any) => {
    let Component = null;
    Component = route.Component;
    const { path, roles = null } = route;

    if (roles && roles !== "all" && user?.role?.name !== "SuperAdmin") {
      if (user && user.role) {
        if (!roles.includes(user.role.name)) {
          Component = Page404;
        }
      } else {
        return null;
      }
    }

    return <Route key={path} path={path} element={<Component />} />;
  });

function Routes({ routes }: any) {
  const { user, isUserRefreshing }: any = useAuth();

  const userRoutes = useMemo(() => {
    if (user) {
      return getUserRoutes(routes, user);
    }

    return getUserRoutes(loginRoutes, user);
  }, [routes, user]);

  return isUserRefreshing ? null : <RRoutes>{userRoutes}</RRoutes>;
}

export default Routes;
