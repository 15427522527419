import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import { Partner } from "types/Partner";
import dayjs from "dayjs";
import { StarIcon, UserIcon } from "@heroicons/react/20/solid";
import { Review } from "types/Review";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import { Link } from "react-router-dom";
import { CurrencyEuroIcon } from "@heroicons/react/24/outline";
import DownloadableQRCode from "common/components/QRCode/DownloadableQRCode";

type PartnerFormProps = {
  partner: Partner | undefined;
  onSubmit: (values: any) => any;
};

const weekdays = Array.from({ length: 7 }, (_, i) => {
  const fnIndex = i === 6 ? 0 : i + 1; // start "monday"
  return {
    key: `${dayjs().day(fnIndex).locale("en").format("dddd")}`.toLowerCase(),
    label: `${dayjs().day(fnIndex).locale("fr").format("dddd")}`,
  };
});

function PartnerForm({ onSubmit, partner }: PartnerFormProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();

  const initialValues = useMemo(
    () =>
      partner?.id
        ? {
            ...partner,
          }
        : {
            title: "",
            code: undefined,
          },
    [partner]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([f]: any, state: any, utils: any) => {
          utils.changeValue(state, f.field, () => f.value);
        },
      }}
      render={({ handleSubmit, values }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(partner?.id ? "editItem.partner" : "newItem.partner")}
              subTitle={values.name}
              icon="BuildingStorefrontIcon"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/partners")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex gap-2 mt-2">
                <div className="md:w-2/3 flex flex-col gap-2">
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="title"
                      title={t("common.title")}
                      required
                    />
                    <FormControl
                      name="description"
                      type="richtext"
                      titleKey={t("forms.description")}
                      required
                    />
                    <FormControl
                      title={t("forms.images")}
                      labelIcon="PhotoIcon"
                      name="images"
                      type="images"
                      limit={4}
                    />
                  </div>

                  <div className="white-box flex flex-col w-full gap-2">
                    <div className="flex flex-row gap-4 font-semibold">
                      <div className="flex-1">{t("forms.day")}</div>
                      <div className="flex-1">{t("forms.openingTime")}</div>
                      <div className="flex-1">{t("forms.closingTime")}</div>
                      <div className="flex-1">{t("forms.isClosed")}</div>
                    </div>
                    {weekdays.map((day) => (
                      <div
                        key={day.key}
                        className="flex flex-row gap-4 items-center"
                      >
                        <div className="flex-1">{day.label}</div>
                        <div className="flex-1">
                          <FormControl
                            name={`schedules.${day.key}.startHour`}
                            disabled={
                              values.schedules?.[`${day.key}`]?.isClosed
                            }
                          />
                        </div>
                        <div className="flex-1">
                          <FormControl
                            name={`schedules.${day.key}.endHour`}
                            disabled={
                              values.schedules?.[`${day.key}`]?.isClosed
                            }
                          />
                        </div>
                        <div className="flex-1">
                          <FormControl
                            name={`schedules.${day.key}.isClosed`}
                            type="switch"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col md:w-1/3 gap-2">
                  {initialValues?.code && (
                    <div className="white-box flex flex-col w-full items-center justify-center">
                      <div>
                        <DownloadableQRCode text={initialValues.code} />
                      </div>
                    </div>
                  )}
                  <div className="white-box flex flex-col w-full gap-2">
                    <FormControl
                      name="contactName"
                      titleKey={t("forms.contactName")}
                    />
                    <FormControl
                      name="contactPhoneNumber"
                      titleKey={t("forms.contactPhoneNumber")}
                    />
                    <FormControl
                      name="addressTitle"
                      titleKey={t("forms.addressTitle")}
                    />
                    <FormControl name="street" titleKey={t("forms.street")} />

                    <div className="flex flex-row gap-4">
                      <FormControl
                        name="postalCode"
                        titleKey={t("forms.postalCode")}
                      />
                      <FormControl
                        name="city"
                        titleKey={t("forms.city")}
                        containerStyle="flex-1"
                      />
                    </div>

                    <FormControl
                      name="gmapsUrl"
                      titleKey={t("forms.gmapsUrl")}
                    />
                  </div>

                  <div className="white-box flex flex-col w-full gap-8">
                    <h2 className="flex flex-row justify-between text-lg font-semibold">
                      Note globale :
                      <div className="flex flex-row gap-1 items-center">
                        <StarIcon width={25} className="text-yellow-500" />

                        <p className="text-gray-600 leading-6 text-xl">
                          <b className="text-gray-900">{partner?.rating}</b>
                          /5
                        </p>
                      </div>
                    </h2>
                    {partner?.reviews?.map((review: Review) => {
                      return (
                        <div key={`review-${review.id}`}>
                          <div className="flex flex-row items-start gap-4">
                            <div className="w-12 h-12 rounded-lg overflow-hidden">
                              <ImageComponent
                                image={
                                  review.author?.avatar
                                    ? ImageFromStrapiMedia(
                                        review.author?.avatar
                                      )?.uri
                                    : undefined
                                }
                              />
                            </div>

                            <div className="flex-1 gap-1">
                              <div className="justify-between gap-1.5">
                                <div className="flex flex-row gap-1 items-center">
                                  <StarIcon
                                    width={25}
                                    className="text-yellow-500"
                                  />

                                  <p className="text-gray-600 leading-6 text-xl">
                                    <b className="text-gray-900">
                                      {review.ratings}
                                    </b>
                                    /5
                                  </p>
                                </div>
                              </div>

                              <p className="mb-1 font-semibold">
                                {review.comment}
                              </p>

                              <p className="mt-2 flex flex-row gap-4">
                                <UserIcon width={24} className="" />
                                <Link to={`/users/edit/${review.author.id}`}>
                                  <span className="border-b-2 border-slate-500 hover:border-emerald-500 text-slate-800">
                                    {review.author.username}
                                  </span>
                                </Link>
                                , le{" "}
                                {dayjs(review?.createdAt).format("DD/MM/YYYY")}
                              </p>
                              {review.transaction && (
                                <p className="mt-2 flex flex-row gap-4">
                                  <CurrencyEuroIcon width={24} className="" />
                                  <Link
                                    to={`/transactions/${review.author.id}`}
                                  >
                                    <span className="border-b-2 border-slate-500 hover:border-emerald-500 text-slate-800">
                                      {review.transaction.content.title}
                                    </span>
                                  </Link>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/partners")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default PartnerForm;
