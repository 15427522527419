import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import { User } from "types";

function UserAvatar({
  user,
  growOnSmallDevice = true,
  forcedSize = "w-24 h-24",
  forcedCompanyLogoSize = "w-12 h-12",
}: {
  user: User;
  growOnSmallDevice?: boolean;
  forcedSize?: string;
  forcedCompanyLogoSize?: string;
}) {
  const { isCompany, companyLogo, avatar } = user || {
    isCompany: null,
    companyLogo: null,
    avatar: null,
  };
  return (
    <div
      className={`flex relative ${
        growOnSmallDevice
          ? "w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24"
          : forcedSize
      } shrink-0`}
    >
      {isCompany ? (
        <>
          <div className="absolute -bottom-2 -right-2">
            <div
              className={`${
                growOnSmallDevice
                  ? "h-8 w-8 xl:h-12 xl:w-12"
                  : forcedCompanyLogoSize
              } overflow-hidden rounded-md`}
            >
              <ImageComponent
                image={
                  companyLogo
                    ? ImageFromStrapiMedia(companyLogo)?.uri
                    : undefined
                }
              />
            </div>
          </div>
          <div className="rounded-lg overflow-hidden w-full">
            <ImageComponent
              image={avatar ? ImageFromStrapiMedia(avatar)?.uri : undefined}
            />
          </div>
        </>
      ) : (
        <ImageComponent
          image={avatar ? ImageFromStrapiMedia(avatar)?.uri : undefined}
        />
      )}
    </div>
  );
}

export default UserAvatar;
