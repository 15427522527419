import { useEffect, useState } from "react";
import qrcode from "qrcode";

import Icon from "components/Icon/Icon";

interface IQRCode {
  text: string;
}
function QRCode({ text }: IQRCode) {
  const [src, setSrc] = useState<string | undefined>();
  useEffect(() => {
    qrcode
      .toDataURL(text)
      .then((url) => {
        setSrc(url);
      })
      .catch(() => {
        /* empty */
      });
  }, [setSrc, text]);

  if (!src) {
    return (
      <div className="w-24 h-24">
        <Icon name="CgSpinner" className="animate-spin" />
      </div>
    );
  }

  return (
    <div>
      <img src={src} alt={src} className="w-24 h-24" />
    </div>
  );
}

export default QRCode;
