import { useMemo, useEffect, useContext } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import { Review } from "types/Review";
import Header from "common/components/Header/Header";
import useRouter from "common/hooks/use-router";
import useList from "common/hooks/useList";
import FilteredList from "common/components/Table/FilteredList";
import AlertsContext from "common/providers/alerts";
import UserSearch from "components/search/UserSearch";
import ButtonGroup from "common/components/ButtonGroup/ButtonGroup";
import ReviewItem from "./ReviewItem";

function Reviews() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const {
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    itemsCount,
    itemsPerPage,
    error: errorList1,
  } = useList<Review>("reviews", {
    defaultSorts: ["createdAt:DESC"],
    populate: [
      "onUser",
      "onUser.avatar",
      "author",
      "author.avatar",
      "author.companyLogo",
      "onMeetingPlace",
      "onMeetingPlace.images",
      "transaction",
      "transaction.content",
    ],
    defaultFilters: {},
  });

  useEffect(() => {
    if (errorList1) {
      setAlert(errorList1, "danger");
    }
  }, [errorList1, setAlert]);

  const { navigate } = useRouter();

  const handleNavigate = (id: any) => {
    navigate(`/reviews/edit/${id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t("filters.author"),
        filterName: "author][username]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("filters.onUser"),
        filterName: "onUser][username]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("filters.onMeetingPlace"),
        filterName: "onMeetingPlace][title]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("filters.onTransaction"),
        filterName: "transaction][id]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("forms.state"),
        filterName: "type",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <ButtonGroup
            className="w-full"
            options={[
              { value: null, label: t("status.all") },
              { value: "onBuyer", label: t("forms.onBuyer") },
              { value: "onSeller", label: t("forms.onSeller") },
              { value: "onMeetingPlace", label: t("forms.onMeetingPlace") },
            ]}
            {...props}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.reviews")}
        subTitle={t("titleLegends.reviews")}
        btnTitle={t("newItem.review")}
        btnSubtitle={t("newItem.reviewLegend")}
        Icon={ExclamationTriangleIcon}
        noAdd
        isBeforeFilters
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={ReviewItem}
        onDelete={undefined}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Reviews;
