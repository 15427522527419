const checkRights = (
  currentUser: any,
  rightKey: string,
  siteIds: number[] = [],
  jokers: string[] = []
) => {
  if ([...jokers].find((jr: string) => currentUser[jr] === true)) {
    return true;
  }
  return !!siteIds.find((siteId: number) =>
    currentUser?.checkMyRight(siteId, rightKey, jokers)
  );
};

export default checkRights;
