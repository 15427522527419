import Button from "common/components/Button/Button";
import { Link } from "react-router-dom";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Field } from "types/Field";

type FieldItemProps = {
  item: Field;
  onDelete: (id: number) => void;
  onNavigate: (id: number) => void;
};

function FieldItem({ item, onDelete, onNavigate }: FieldItemProps) {
  const { user: currentUser } = useAuth();
  const canEditItem = currentUser?.isAdmin;

  const editLink = `/fields/edit/${item.id}`;

  const { t } = useTranslation();

  return (
    <div className="flex px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem ? "pointer-events-none flex flex-1" : "flex flex-1"
        }
      >
        <div className="flex flex-col flex-1 justify-between pl-4 pr-6">
          <div className="flex my-1 gap-3 items-center">
            <div className="flex flex-col justify-center overflow-hidden">
              <h4 className="text-xl font-bold text-primary border border-primary rounded-full leading-6 w-8 h-8 text-center py-1">
                {item.order}
              </h4>
            </div>
            <div className="flex-1">
              <h4 className="text-xl font-bold text-primary leading-4">
                {item.label}
              </h4>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-end items-end text-xs lg:border-l lg:pl-4 lg:min-w-[22em] border-slate-150 gap-2">
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                title={t("actions.edit")}
                type="valid-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FieldItem;
