import { Constants } from "constants/Constants";

const ImageFromStrapiMedia = (media: any) => {
  if (!media?.url) {
    return { uri : '/images/fallback.jpg'}
  }
  
  return media?.url.startsWith("http")
    ? {
        uri: media.url,
      }
    : {
        uri: `${Constants.ROOT_URL}${media?.url}`,
      };
};

export default ImageFromStrapiMedia;
