import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import PageLoading from "common/components/PageLoading/PageLoading";
import Icon from "components/Icon/Icon";
import Status from "common/components/Status/Status";
import AlertsContext from "common/providers/alerts";
import { Review } from "types/Review";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { Link } from "react-router-dom";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import {
  ArrowDownCircleIcon,
  EnvelopeIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import dayjs from "dayjs";
import Button from "common/components/Button/Button";

function ReviewDetails() {
  const router = useRouter();

  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const { navigate } = useRouter();

  const { item, isFetching, updateItem, error }: any = useItem<Review>(
    "reviews",
    id,
    {
      populate: [
        "onUser",
        "onUser.avatar",
        "author",
        "author.avatar",
        "onMeetingPlace",
        "onMeetingPlace.images",
        "transaction",
        "transaction.content",
        "transaction.content.images",
      ],
    }
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const onRemove = useCallback(async () => {
    try {
      await updateItem(item.id, {
        isRemoved: !item.isRemoved,
      });
      navigate("/reviews");
    } catch (e) {
      setAlert(e, "danger");
      return e;
    }
    return true;
  }, [updateItem, setAlert, navigate, item]);

  if (isFetching && !item) {
    return <PageLoading />;
  }

  return !item ? null : (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <DetailHeader
        backLink="/reviews"
        title={t("titlePages.reviews")}
        TitleIcon={
          <div>
            <Icon name="BsStarFill" className="w-16 h-16 mr-1 text-primary" />
          </div>
        }
        Status={
          <div className="flex gap-2 flex-wrap">
            <Status status={item?.type} />
          </div>
        }
      />

      <div className="responsive-flex gap-2 mt-2">
        <div className="flex-1">
          <div className="flex flex-col gap-2 w-full">
            {/* Description */}
            <div className="responsive-flex flex flex-col lg:flex-row gap-4 items-stretch">
              {/* Column 1 : auteur */}
              <div className="responsive-flex gap-4 flex-col w-full lg:basis-1/3">
                <div className="white-box flex flex-col w-full items-center">
                  <SectionTitle
                    title={t(`forms.users`)}
                    icon="UsersIcon"
                    className="text-slate-800 w-full"
                  />
                  <div className="w-full responsive-flex my-4 py-4 flex-row border border-gray-600 shadow-lg rounded-lg">
                    <Link
                      to={`/users/edit/${item?.author?.id}`}
                      className="responsive-flex-lg flex-1 px-4"
                    >
                      {item?.author && (
                        <>
                          <div className="flex relative w-40 h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
                            <UserAvatar
                              user={item?.author}
                              growOnSmallDevice={false}
                            />
                          </div>
                          <div className="flex flex-col flex-1 justify-between lg:pl-4">
                            <div className="flex h-full gap-3">
                              <div className="grid w-full gap-1 pt-3 lg:py-0">
                                <div className="flex items-center gap-1 mb-1 text-xl">
                                  <span className="font-bold text-primary leading-4">
                                    {item?.author?.username}
                                  </span>
                                  {" -  "}
                                  <span className="leading-4">
                                    {item?.author?.firstname}
                                  </span>
                                  <span className="leading-4">
                                    {item?.author?.lastname}
                                  </span>
                                </div>

                                <div className="flex items-center gap-1 text-sm text-primary">
                                  {item?.author?.isCompany && (
                                    <>
                                      <HiOutlineBuildingOffice2 className="w-4 h-4" />
                                      <span className="text-md font-bold">
                                        {item?.author?.companyName}
                                      </span>
                                    </>
                                  )}
                                </div>
                                <div className="flex flex-row w-full items-center justify-between mt-1 self-end place-self-end justify-self-end">
                                  <div className="flex items-center gap-1">
                                    {item?.author?.email && (
                                      <>
                                        <EnvelopeIcon className="w-3 h-3" />
                                        <span className="font-bold">
                                          {item?.author?.email}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Link>
                  </div>

                  <ArrowDownCircleIcon width={36} />

                  <div className="w-full responsive-flex my-4 py-4 flex-row border border-gray-600 shadow-lg rounded-lg">
                    <Link
                      to={
                        item?.onUser
                          ? `/users/edit/${item?.onUser?.id}`
                          : `/partners/edit/${item?.onMeetingPlace?.id}`
                      }
                      className="responsive-flex-lg flex-1 px-4"
                    >
                      <div className="flex relative w-40 h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
                        {item?.onUser ? (
                          <UserAvatar
                            user={item?.onUser}
                            growOnSmallDevice={false}
                          />
                        ) : (
                          <ImageComponent
                            image={
                              item?.onMeetingPlace?.images?.[0]
                                ? ImageFromStrapiMedia(
                                    item.onMeetingPlace.images[0]
                                  )?.uri
                                : undefined
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col flex-1 justify-between lg:pl-4">
                        <div className="flex h-full gap-3">
                          <div className="grid w-full gap-1 pt-3 lg:py-0">
                            <div className="flex items-center gap-1 mb-1 text-xl">
                              {item?.onUser ? (
                                <>
                                  <span className="font-bold text-primary leading-4">
                                    {item?.onUser?.username}
                                  </span>
                                  {" -  "}
                                  <span className="leading-4">
                                    {item?.onUser?.firstname}
                                  </span>
                                  <span className="leading-4">
                                    {item?.onUser?.lastname}
                                  </span>
                                </>
                              ) : (
                                <span className="font-bold text-primary leading-4">
                                  {item?.onMeetingPlace?.title}
                                </span>
                              )}
                            </div>
                            {item?.onUser?.isCompany && (
                              <div className="flex items-center gap-1 text-sm text-primary">
                                <HiOutlineBuildingOffice2 className="w-4 h-4" />
                                <span className="text-md font-bold">
                                  {item?.onUser?.companyName}
                                </span>
                              </div>
                            )}
                            <div className="flex flex-row w-full items-center justify-between mt-1 self-end place-self-end justify-self-end">
                              <div className="flex items-center gap-1">
                                {item?.onUser?.email && (
                                  <>
                                    <EnvelopeIcon className="w-3 h-3" />
                                    <span className="font-bold">
                                      {item?.onUser?.email}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Column 2 : content */}

              <div className="responsive-flex gap-4 flex-col w-full lg:basis-1/3">
                <div className="white-box flex-col w-full h-full">
                  <SectionTitle
                    title={t(`forms.review`)}
                    icon="ChatBubbleOvalLeftIcon"
                    className="text-slate-800"
                  />
                  <div className="responsive-flex-lg mt-2 mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 flex-1">
                    <div className="flex flex-col flex-1 justify-between">
                      <div className="flex flex-row justify-between gap-2">
                        {item.comment && (
                          <div className=" text-black text-xl">
                            {item.comment}
                          </div>
                        )}

                        <div className="flex flex-row gap-1 items-center">
                          <StarIcon width={36} className="text-yellow-500" />

                          <p className="text-gray-600 leading-6 text-2xl">
                            <b className="text-gray-900">{item?.ratings}</b>
                            /5
                          </p>
                        </div>
                      </div>

                      <div className="text-m flex pb-4 mb-4 border-b border-b-slate-200">
                        <div className="flex items-center">
                          <Icon name="ClockIcon" className="w-8 h-8 mr-1" />
                          <span>{dayjs(item?.createdAt).format("LL")}</span>
                        </div>
                      </div>

                      <Button
                        title={
                          item?.isRemoved
                            ? t("actions.cancelRemove")
                            : t("actions.remove")
                        }
                        type={item?.isRemoved ? "valid" : "error"}
                        icon={item?.isRemoved ? "EyeIcon" : "EyeSlashIcon"}
                        onClick={onRemove}
                        className="self-center"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Column 3 : transaction */}
              <div className="responsive-flex gap-4 flex-col w-full lg:basis-1/3">
                <div className="white-box flex-col w-full h-full">
                  <SectionTitle
                    title={t(`forms.transaction`)}
                    icon="MdCurrencyExchange"
                    className="text-slate-800"
                  />
                  <div className="responsive-flex my-4 py-4 flex-row border border-gray-600 shadow-lg rounded-lg">
                    <Link
                      to={`/transactions/${item?.transaction?.id}`}
                      className="responsive-flex-lg flex-1 px-4"
                    >
                      <div className="flex relative w-40 h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0 rounded-lg overflow-hidden">
                        <ImageComponent
                          image={
                            item?.transaction?.content?.images?.[0]
                              ? ImageFromStrapiMedia(
                                  item.transaction.content.images[0]
                                )?.uri
                              : undefined
                          }
                        />
                      </div>
                      <div className="flex flex-col flex-1 justify-between lg:pl-4">
                        <div className="flex flex-col items-start gap-1 pt-3 lg:py-0">
                          <div className="flex items-center gap-1 mb-1 text-xl">
                            <span className="font-bold text-primary leading-6">
                              {item?.transaction?.content?.title}
                            </span>
                          </div>

                          {item?.transaction?.status ? (
                            <Status status={item.transaction.status} />
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewDetails;
