import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Category } from "types/Category";

const processOptions = (options: Category[]) =>
  options.map(({ id, name, logo }) => ({
    value: id,
    label: name,
    logo,
  }));

function CategorySelect(props: any) {
  const { filterOnParents, excludeIds } = props;

  const filters = filterOnParents
    ? [
        { name: "parentCategory][id][$null", value: true },
        { name: "id][$notIn", value: excludeIds || [] },
      ]
    : [];

  return (
    <RemoteSelect
      url="categories"
      processOptions={processOptions}
      filters={filters}
      populate={["parentCategory", "logo"]}
      {...props}
    />
  );
}

export default CategorySelect;
