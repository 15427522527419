// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { UsersIcon } from "@heroicons/react/24/outline";
import Header from "common/components/Header/Header";
import { User } from "types";

import FilteredList from "common/components/Table/FilteredList";
import useAuth from "common/hooks/useAuth";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import RoleSelect from "components/select/RoleSelect/RoleSelect";
import AlertsContext from "common/providers/alerts";
import CompanyPersonSelect from "components/select/CompanyPersonSelect/CompanyPersonSelect";
import UserSearch from "components/search/UserSearch";
import UserItem from "./UserItem";

function Users() {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();
  const canManageUsers = currentUser?.isAdmin;

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<User>("users", {
    defaultSort: "createdAt:DESC",
    populate: ["role", "avatar", "companyLogo"],
    cachePrefix: "Users",
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const { removeItem } = useItem<User>("users", "");

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e: any) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const handleNavigate = (id: any) => {
    navigate(`/users/edit/${id}`);
  };

  const styleTop = "md:w-1/3 xl:flex-1 xl:w-auto";
  const styleBottom = "md:w-1/3 mt-2 xl:flex-1 xl:w-auto xl:mt-0";

  const showRoles = canManageUsers;

  const columns = useMemo(
    () => [
      {
        Header: t("filters.search"),
        filterName: "username$containsi",
        columnClasses: styleTop,
        filterLabel: t("filters.search"),
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("forms.role"),
        hidden: !showRoles,
        filterName: "role][name",
        filterComponent: (props: any) => <RoleSelect isFilter {...props} />,
        columnClasses: styleTop,
      },
      {
        Header: t("forms.type"),
        filterName: "isCompany",
        filterComponent: (props: any) => (
          <CompanyPersonSelect isFilter {...props} />
        ),
        columnClasses: styleTop,
      },
      {
        Header: t("forms.confirmed"),
        filterName: "blocked",
        filterComponent: (props: any) => (
          <div className="pt-2">
            <ToggleSwitch rightLabel={t("forms.blocked")} {...props} />
          </div>
        ),
        // headerClasses: "mb-3",
        columnClasses: styleBottom,
      },
    ],
    [t, showRoles]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.users")}
        subTitle={t("titleLegends.users")}
        btnTitle={t("newItem.user")}
        btnSubtitle={t("newItem.userLegend")}
        Icon={UsersIcon}
        addLink="/users/add"
        addLinkDisabled={!canManageUsers}
        isBeforeFilters
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={UserItem}
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Users;
