import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import useItem from "common/hooks/useItem";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import HeaderButtons from "common/components/DetailHeader/HeaderButtons";
import PageLoading from "common/components/PageLoading/PageLoading";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import Icon from "components/Icon/Icon";
import Status from "common/components/Status/Status";
import AlertsContext from "common/providers/alerts";
import fetchJSON from "common/utils/fetchJSON";
import Modal from "common/components/Modal/Modal";
import { UserVerification } from "types/UserVerification";
import UserCard from "components/UserCard/UserCard";
import { Link } from "react-router-dom";
import { isStrapiDocument } from "utils/isStrapiDocument";

function UserVerificationDetails() {
  const router = useRouter();
  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const [fullScreenImgIndex, setFullScreenImgIndex] = useState<number | null>(
    null
  );

  const { item, fetchItem, isFetching, error }: any = useItem(
    "user-verifications",
    id,
    {
      populate: [
        "user",
        "user.avatar",
        "idCardFrontSide",
        "idCardBackSide",
        "personalPicture",
        "companyRegister",
      ],
    }
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const {
    user,
    idCardBackSide,
    idCardFrontSide,
    personalPicture,
    companyRegister,
    status,
    createdAt,
    updatedAt,
    verificationDate,
  } = (item as UserVerification) || {};

  type UserVerificationStatus = "pending" | "validated" | "denied";

  const isPending = status === "pending";
  const isValidated = status === "validated";
  const isDenied = status === "denied";

  const images = useMemo(() => {
    if (!item || isFetching) {
      return [];
    }
    const imgList = [
      {
        ...idCardFrontSide,
        isDocument: idCardFrontSide && isStrapiDocument(idCardFrontSide),
        imageCaption: t("userVerifications.idCardFrontSide"),
      },
      {
        ...idCardBackSide,
        isDocument: idCardBackSide && isStrapiDocument(idCardBackSide),
        imageCaption: t("userVerifications.idCardBackSide"),
      },
      {
        ...personalPicture,
        isDocument: personalPicture && isStrapiDocument(personalPicture),
        imageCaption: t("userVerifications.personalPicture"),
      },
    ];
    if (user?.isCompany) {
      imgList.push({
        ...companyRegister,
        isDocument: companyRegister && isStrapiDocument(companyRegister),
        imageCaption: t("userVerifications.companyRegister"),
      });
    }
    return imgList;
  }, [
    item,
    user,
    isFetching,
    idCardBackSide,
    idCardFrontSide,
    personalPicture,
    companyRegister,
    t,
  ]);

  const updateUserProfile = useCallback(
    async (newStatus: UserVerificationStatus) => {
      const res = await fetchJSON({
        method: "PUT",
        url: `user-verifications/${id}`,
        payload: {
          data: { status: newStatus },
        },
      });
      if (res) {
        fetchItem();
      }
    },
    [fetchItem, id]
  );

  const UserVerificationHeaderInfos = useMemo(
    () => (
      <div className="w-full">
        <div className="flex items-center text-xs gap-1 field-title">
          <Icon name="CheckBadgeIcon" className="w-4 h-4" />
          {t("userVerifications.submittedSince", {
            since: dayjs(createdAt).format("LL"),
          })}
        </div>
        <div className="responsive-flex items-center gap-3">
          <div className="flex flex-row items-center text-ternary-800">
            <div className="text-sm flex">
              <div className="flex items-center">
                <span>
                  {t(
                    isValidated
                      ? "userVerifications.validatedSince"
                      : isDenied
                      ? "userVerifications.deniedSince"
                      : "userVerifications.pendingSince",
                    {
                      since: dayjs(
                        isValidated || isDenied ? verificationDate : updatedAt
                      ).fromNow(),
                    }
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    [t, createdAt, isValidated, isDenied, verificationDate, updatedAt]
  );

  const nextImageIndex = useCallback(
    (currentIndex: number): number => {
      const nextIndex: number = (currentIndex + 1) % images.length;
      if (images[nextIndex].isDocument) {
        return nextImageIndex(nextIndex);
      }
      return nextIndex;
    },
    [images]
  );

  const previousImageIndex = useCallback(
    (currentIndex: number): number => {
      const previousIndex = (currentIndex + images.length - 1) % images.length;
      if (images[previousIndex].isDocument) {
        return previousImageIndex(previousIndex);
      }
      return previousIndex;
    },
    [images]
  );

  if (isFetching && !item) {
    return <PageLoading />;
  }

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      {fullScreenImgIndex !== null && (
        <Modal
          title={images[fullScreenImgIndex]?.imageCaption}
          visible={fullScreenImgIndex !== null}
          onCloseModal={() => setFullScreenImgIndex(null)}
          showCloseModalFooter
          maxWindowSize
        >
          <div className="flex flex-row gap-0 justify-center items-stretch">
            <div
              className="flex flex-col justify-center cursor-pointer text-slate-600 hover:text-black hover:pt-1"
              onClick={() =>
                setFullScreenImgIndex(previousImageIndex(fullScreenImgIndex))
              }
            >
              <Icon name="ArrowLeftIcon" className="w-12 h-12" />
            </div>
            <div className="rounded-lg overflow-hidden opacity-100 bg-white">
              <Link
                to={ImageFromStrapiMedia(images[fullScreenImgIndex])?.uri}
                target="_blank"
                className="cursor-zoom-in"
              >
                <ImageComponent
                  image={ImageFromStrapiMedia(images[fullScreenImgIndex])?.uri}
                />
              </Link>
            </div>
            <div
              className="flex flex-col justify-center cursor-pointer text-slate-600 hover:text-black hover:pt-1"
              onClick={() =>
                setFullScreenImgIndex(nextImageIndex(fullScreenImgIndex))
              }
            >
              <Icon name="ArrowRightIcon" className="w-12 h-12" />
            </div>
          </div>
        </Modal>
      )}
      <DetailHeader
        backLink="/user-verifications"
        title={t("userVerifications.title")}
        Status={
          <div className="flex gap-2 flex-wrap">
            <Status status={status} />
          </div>
        }
        TitleIcon={
          <div>
            <Icon
              name="CheckBadgeIcon"
              className="w-16 h-16 mr-1 text-primary"
            />
          </div>
        }
        HeaderInfos={UserVerificationHeaderInfos}
        SubTitle={
          <span className="font-bold">
            {t("userVerifications.userRequest", {
              username: `${user?.firstname} ${user?.lastname}`,
            })}
          </span>
        }
        HeaderButtons={
          <HeaderButtons
            // editUrl={`/contents/edit/${id}`}
            // submit
            // onSubmit={handleSubmit}
            // submitDisabled={!canSubmit}
            // validate or reject
            onValidate={() => updateUserProfile("validated")}
            validateDisabled={isValidated}
            onReject={() => updateUserProfile("denied")}
            rejectDisabled={!isValidated && !isPending}
            // unpublish
            // onUnpublish={handleUnpublish}
            // unpublishDisabled={!canUnpublish}
            // archive
            // onArchive={handleArchive}
            // archiveDisabled={!canArchive}
            // TODO : ne sert plus à rien puisque géré par des routes customs (!=update)
            // Il faudrait intégrer les fetchJSON dans un provider pour gérer les chargements de changement de statut
            isPublishing={isFetching}
          />
        }
      />

      <div className="responsive-flex gap-2 mt-2">
        <div className="flex-1">
          <div className="flex flex-col gap-2 w-full">
            {/* Description */}
            <div className="responsive-flex flex flex-col xl:flex-row-reverse gap-4 items-start">
              {/* Column 1 */}
              <div className="white-box responsive-flex gap-2 flex-col w-full flex-1">
                <UserCard user={user} />
              </div>

              {/* column 2 */}
              <div className="white-box flex-1">
                <div className="gap-4 w-full grid grid-cols-2 justify-center">
                  {images?.map((image, index) => {
                    return (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={`image-${index}`}
                        className="aspect-square w-full mb-2"
                      >
                        <h2 className="text-xl font-semibold mb-2">
                          {image?.imageCaption}
                        </h2>

                        {image.isDocument ? (
                          <Link
                            className="rounded-lg overflow-hidden w-full h-full cursor-pointer"
                            target="_blank"
                            to={ImageFromStrapiMedia(image)?.uri}
                          >
                            <ImageComponent
                              image={ImageFromStrapiMedia(image)?.uri}
                            />
                          </Link>
                        ) : (
                          <div
                            className="rounded-lg overflow-hidden w-full h-full cursor-pointer"
                            onClick={() => {
                              setFullScreenImgIndex(index);
                            }}
                          >
                            <ImageComponent
                              image={ImageFromStrapiMedia(image)?.uri}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserVerificationDetails;
