import { useTranslation } from "react-i18next";
import Select from "common/components/Select/Select";

function ContentStatusSelect(props: any) {
  const { t } = useTranslation();
  return (
    <Select
      options={["draft", "submitted", "published", "rejected", "closed"].map(
        (name) => ({
          value: name,
          label: t(`status.${name}`),
        })
      )}
      {...props}
    />
  );
}

export default ContentStatusSelect;
