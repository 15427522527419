import Select from "common/components/Select/Select";
import { useTranslation } from "react-i18next";

function CompanyPersonSelect(props: any) {
  const { t } = useTranslation();

  return (
    <Select
      type="select"
      options={[
        {
          value: null,
          label: t("status.all"),
        },
        {
          value: true,
          label: t("forms.companiesOnly"),
        },
        {
          value: false,
          label: t("forms.personsOnly"),
        },
      ]}
      {...props}
    />
  );
}

export default CompanyPersonSelect;
