import React, { useRef, useEffect, useCallback } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import QRCode from "qrcode.react";

interface IDownloadableQRCode {
  text: string;
}

function DownloadableQRCode({ text }: IDownloadableQRCode) {
  const qrCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const drawQRCodeWithText = useCallback(() => {
    const canvas = qrCanvasRef.current;
    if (canvas) {
      const qrSize = 256;
      const padding = 20;
      const totalSize = qrSize + padding * 2;

      // Set the canvas size to include padding
      canvas.width = totalSize;
      canvas.height = totalSize + 30; // Additional space for text

      const context = canvas.getContext("2d");
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Draw a white background
        context.fillStyle = "white";
        context.fillRect(0, 0, canvas.width, canvas.height);

        const qrCodeSvg = document.getElementById(
          "qrCodeSvg"
        ) as unknown as SVGElement;
        if (qrCodeSvg) {
          const img = new Image();
          const svgData = new XMLSerializer().serializeToString(qrCodeSvg);
          const svgBlob = new Blob([svgData], {
            type: "image/svg+xml;charset=utf-8",
          });
          const url = URL.createObjectURL(svgBlob);

          img.onload = () => {
            // Draw the QR code with padding
            context.drawImage(img, padding, padding, qrSize, qrSize);

            // Draw the text below the QR code
            context.font = "28px Arial";
            context.textAlign = "center";
            context.fillStyle = "black";
            context.fillText(text, canvas.width / 2, qrSize + padding + 40); // Adjust text position

            URL.revokeObjectURL(url);
          };
          img.src = url;
        }
      }
    }
  }, [text]);

  useEffect(() => {
    drawQRCodeWithText();
  }, [drawQRCodeWithText]);

  const openQrInNewTab = () => {
    const canvas = qrCanvasRef.current;
    if (canvas) {
      const dataUrl = canvas.toDataURL("image/png");
      const newTab = window.open();
      if (newTab) {
        newTab.document.open();
        newTab.document.write(`
          <html>
            <head>
              <title>QR Code</title>
              <style>
                body {
                  margin: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                  background-color: black;
                }
                img {
                  max-width: 100%;
                  max-height: 100%;
                }
              </style>
            </head>
            <body>
              <img src="${dataUrl}" alt="QR Code" />
            </body>
          </html>
        `);
        newTab.document.close();
      }
    }
  };

  const downloadQr = () => {
    const canvas = qrCanvasRef.current;
    if (canvas) {
      const dataUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "qr-code.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="flex-col items-center self-start">
      <div style={{ display: "none" }}>
        <QRCode
          id="qrCodeSvg"
          value={text}
          size={256}
          renderAs="svg"
          className="self-center"
        />
      </div>
      <div className="flex flex-1 self-center ml-2">
        <canvas ref={qrCanvasRef} />
      </div>
      <div className="flex flex-row justify-around gap-4">
        <button type="button" onClick={openQrInNewTab}>
          Ouvrir dans un nouvel onglet
        </button>
        <button type="button" onClick={downloadQr}>
          Télécharger
        </button>
      </div>
    </div>
  );
}

export default DownloadableQRCode;
