import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import PageLoading from "common/components/PageLoading/PageLoading";
import Icon from "components/Icon/Icon";
import Status from "common/components/Status/Status";
import AlertsContext from "common/providers/alerts";
import { Report } from "types/Report";
import UserAvatar from "components/UserAvatar/UserAvatar";
import UserBadges from "components/UserBadges/UserBadges";
import { Link } from "react-router-dom";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import TooltipWrapper from "common/components/TooltipWrapper/TooltipWrapper";
import formatPrice from "utils/formatPrice";
import dayjs from "dayjs";
import { FiChevronsRight } from "react-icons/fi";
import truncateString from "common/utils/truncateString";
import getFlattenText from "common/utils/getFlattenText";
import Button from "common/components/Button/Button";
import UserCard from "components/UserCard/UserCard";

function ReportDetails() {
  const router = useRouter();
  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const { item, isFetching, updateItem, error }: any = useItem("reports", id, {
    populate: [
      "user",
      "user.avatar",
      "user.companyLogo",
      "author",
      "author.avatar",
      "author.companyLogo",
      "content",
      "content.author",
      "content.author.avatar",
      "content.author.companyLogo",
      "content.images",
      "transaction",
      "transaction.content",
      "transaction.content.images",
      "transaction.buyer",
      "transaction.buyer.avatar",
      "transaction.seller",
      "transaction.seller.avatar",
    ],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const { content, user, transaction, author, description, state } =
    (item as Report) || {};

  const typeContent: any = useMemo(() => {
    if (item?.status === "transaction") {
      return {
        name: t("common.transaction"),
        icon: "ChatBubbleLeftRightIcon",
        label: item?.transaction.buyer.username,
        key: "Transaction",
        foreignKey: "Transaction",
        url: `/transactions/${transaction?.id}`,
      };
    }
    if (item?.status === "content") {
      return {
        name: t("common.content"),
        icon: "CalendarDaysIcon",
        label: item.content.title,
        key: "Annonce",
        foreignKey: "Content",
        url: `/contents/${content?.id}`,
      };
    }
    if (item?.status === "user") {
      return {
        name: t("common.user"),
        icon: "UserIcon",
        label: item.user.username,
        key: "Utilisateur",
        foreignKey: "User",
        url: `/users/edit/${user?.id}`,
      };
    }
    return null;
  }, [content, item, t, transaction, user]);

  const canProcess = state === "unprocessed";
  const canUnprocess = state === "processed";

  const ReportHeaderInfos = useMemo(
    () => (
      <div className="w-full">
        <div className="flex items-center gap-1 field-title">
          <Icon name={typeContent?.icon} className="w-4 h-4" />
          <span>
            {t("reports.reportedItemOfType", { item: typeContent?.key })}
          </span>
        </div>
      </div>
    ),
    [t, typeContent]
  );

  const handleProcess = useCallback(() => {
    updateItem(id, { state: "processed" });
  }, [id, updateItem]);

  const handleUnprocess = useCallback(() => {
    updateItem(id, { state: "unprocessed" });
  }, [id, updateItem]);

  if (isFetching && !item) {
    return <PageLoading />;
  }

  return !item ? null : (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <DetailHeader
        backLink="/reports"
        title={t("titlePages.reports")}
        TitleIcon={
          <div>
            <Icon
              name="ExclamationTriangleIcon"
              className="w-16 h-16 mr-1 text-primary"
            />
          </div>
        }
        Status={
          <div className="flex gap-2 flex-wrap">
            <Status status={state} />
          </div>
        }
        HeaderInfos={ReportHeaderInfos}
        HeaderButtons={
          <div className="responsive-flex-lg gap-1 lg:justify-end w-full mt-3 lg:mt-0 items-start lg:items-center">
            {canProcess && (
              <Button
                title={t("actions.process")}
                type="valid"
                icon="CheckIcon"
                onClick={handleProcess}
                // compact
              />
            )}
            {canUnprocess && (
              <Button
                title={t("actions.unprocess")}
                type="error-line"
                icon="MdOutlineUnpublished"
                onClick={handleUnprocess}
                // compact
              />
            )}
          </div>
        }
      />

      <div className="responsive-flex gap-2 mt-2">
        <div className="flex-1">
          <div className="flex flex-col gap-2 w-full">
            {/* Description */}
            <div className="responsive-flex flex flex-col lg:flex-row gap-4 items-start">
              {/* Column 1 */}
              <div className="responsive-flex gap-4 flex-col w-full lg:basis-1/2">
                <div className="white-box flex-col w-full">
                  <SectionTitle
                    title={t(`reports.reported${typeContent?.foreignKey}`)}
                    icon="ExclamationTriangleIcon"
                    className="text-secondary"
                  />
                  <div className="responsive-flex my-4 py-4 flex-row border border-gray-600 shadow-lg rounded-lg">
                    <Link
                      to={typeContent?.url}
                      className="responsive-flex-lg flex-1 px-4"
                    >
                      <>
                        {item.status === "user" && user && (
                          <>
                            <div className="flex relative w-40 h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
                              <UserAvatar
                                user={user}
                                growOnSmallDevice={false}
                              />
                            </div>
                            <div className="flex flex-col flex-1 justify-between lg:pl-4">
                              <div className="flex h-full gap-3">
                                <div className="grid w-full gap-1 pt-3 lg:py-0">
                                  <div className="flex items-center gap-1 mb-1 text-xl">
                                    <span className="font-bold text-primary leading-4">
                                      {user?.username}
                                    </span>
                                    {" -  "}
                                    <span className="leading-4">
                                      {user?.firstname}
                                    </span>
                                    <span className="leading-4">
                                      {user?.lastname}
                                    </span>
                                  </div>

                                  <div className="flex items-center gap-1 text-sm text-primary">
                                    {user?.isCompany && (
                                      <>
                                        <HiOutlineBuildingOffice2 className="w-4 h-4" />
                                        <span className="text-md font-bold">
                                          {user?.companyName}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                  <div className="flex flex-row w-full items-center justify-between mt-1 self-end place-self-end justify-self-end">
                                    <div className="flex items-center gap-1">
                                      {user?.email && (
                                        <>
                                          <EnvelopeIcon className="w-3 h-3" />
                                          <span className="font-bold">
                                            {user?.email}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {item.status === "content" && content && (
                          <div className="responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0">
                            <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
                              <div className="">
                                <ImageComponent
                                  image={
                                    content?.images?.[0]
                                      ? ImageFromStrapiMedia(content?.images[0])
                                          ?.uri
                                      : undefined
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex flex-col flex-1 justify-start overflow-hidden">
                              {/* head */}
                              <div className="flex flex-col xl:flex-row w-full gap-4">
                                <div className="flex flex-col flex-1">
                                  <h4 className="text-xl font-bold text-primary leading-6">
                                    {content?.title}
                                  </h4>
                                </div>
                              </div>

                              <div className="flex flex-col xl:flex-row w-full gap-4">
                                <div className="flex flex-row flex-1">
                                  <h4 className="font-bold text-md text-slate-500 leading-6 flex flex-row items-center">
                                    <Icon
                                      name="UserIcon"
                                      className="w-4 h-4 mr-1"
                                    />
                                    <TooltipWrapper
                                      className="mr-1 my-1"
                                      content={
                                        <span>{content?.author?.email}</span>
                                      }
                                      place="top"
                                    >
                                      {content?.author?.username}
                                    </TooltipWrapper>
                                  </h4>
                                  <UserBadges
                                    user={content?.author}
                                    classname="px-2"
                                    noText
                                    isSmall
                                  />
                                </div>
                              </div>

                              <div className="responsive-flex-lg flex-row mt-1">
                                <div className="text-sm flex basis-1/2">
                                  <div className="flex items-center mr-4">
                                    <span className="font-bold text-lg text-secondary">
                                      {formatPrice(content?.price)}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex flex-row basis-1/2 items-center text-ternary-800">
                                  <div className="text-sm flex">
                                    <div className="flex items-center">
                                      <Icon
                                        name="ClockIcon"
                                        className="w-4 h-4 mr-1"
                                      />
                                      <span>
                                        {content?.publishedDate
                                          ? dayjs(
                                              content?.publishedDate
                                            ).format("LL")
                                          : t("forms.notPublishedYet")}
                                      </span>
                                    </div>
                                  </div>
                                  {content?.endPublishedDate && (
                                    <>
                                      <div className="py-1 mx-1">
                                        <FiChevronsRight className="w-3 h-3 text-slate-500" />
                                      </div>
                                      <div className="text-sm flex">
                                        <div className="flex items-center">
                                          <span>
                                            {dayjs(
                                              content?.endPublishedDate
                                            ).format("LL")}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="responsive-flex-lg mt-1" />

                              {/* foot */}
                              <div className="flex flex-col gap-2 pt-1 border-t border-slate-200">
                                {content?.description && (
                                  <div className="text-sm text-gray-500 pt-2">
                                    {truncateString(
                                      getFlattenText(content?.description),
                                      150
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {item.status === "transaction" && transaction && (
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-row">
                              <div className="flex flex-col gap-1">
                                <SectionTitle
                                  title={t("forms.priceValidated")}
                                  icon="WalletIcon"
                                  className="text-secondary"
                                />
                                <div className="responsive-flex flex-row">
                                  <span className="font-bold text-xl pl-4">
                                    {formatPrice(transaction.finalPrice)}
                                  </span>
                                </div>
                                <SectionTitle
                                  title={t("forms.meetingTime")}
                                  icon="BookmarkIcon"
                                  className="text-secondary mt-2"
                                />
                                <div className="responsive-flex flex-row">
                                  <span className="font-bold text-xl py-1 pl-4">
                                    {transaction.startMeetingDate &&
                                    transaction.endMeetingDate
                                      ? `Le ${dayjs(
                                          transaction.startMeetingDate
                                        ).format("LL")} de ${dayjs(
                                          transaction.startMeetingDate
                                        ).format("H:mm")} à ${dayjs(
                                          transaction.endMeetingDate
                                        ).format("H:mm")}`
                                      : t("transactions.noMeetingDate")}
                                  </span>
                                </div>
                                <SectionTitle
                                  title={t("forms.meetingPlace")}
                                  icon="BookmarkIcon"
                                  className="text-secondary mt-2"
                                />
                                {transaction.finalMeetingPlace ? (
                                  <div className="responsive-flex flex-col">
                                    {transaction.finalMeetingPlace
                                      .addressTitle && (
                                      <div className="responsive-flex flex-row">
                                        <span className="font-bold text-xl py-1 pl-4">
                                          {
                                            transaction.finalMeetingPlace
                                              .addressTitle
                                          }
                                        </span>
                                      </div>
                                    )}
                                    {transaction.finalMeetingPlace.street && (
                                      <div className="responsive-flex flex-row">
                                        <span className="font-medium text-xl pl-4">
                                          {`${transaction.finalMeetingPlace.street}`}
                                        </span>
                                      </div>
                                    )}
                                    {transaction.finalMeetingPlace.postalCode &&
                                      transaction.finalMeetingPlace.city && (
                                        <div className="responsive-flex flex-row">
                                          <span className="font-medium text-xl pl-4">
                                            {`${transaction.finalMeetingPlace.postalCode} ${transaction.finalMeetingPlace.city}`}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                ) : (
                                  <div className="responsive-flex flex-row">
                                    <span className="font-bold text-xl py-1 pl-4">
                                      {t("transactions.noMeetingPlace")}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="responsive-flex gap-2 flex-col w-full lg:basis-1/2">
                                <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
                                  <UserCard
                                    user={transaction.buyer}
                                    title={t("forms.buyer")}
                                  />
                                </div>
                                <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
                                  <UserCard
                                    user={transaction.seller}
                                    title={t("forms.seller")}
                                  />
                                </div>
                              </div>
                            </div>
                            {transaction?.content && (
                              <div className="responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0">
                                <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
                                  <div className="">
                                    <ImageComponent
                                      image={
                                        transaction?.content?.images?.[0]
                                          ? ImageFromStrapiMedia(
                                              transaction?.content?.images[0]
                                            )?.uri
                                          : undefined
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-col flex-1 justify-start overflow-hidden">
                                  {/* head */}
                                  <div className="flex flex-col xl:flex-row w-full gap-4">
                                    <div className="flex flex-col flex-1">
                                      <h4 className="text-xl font-bold text-primary leading-6">
                                        {transaction?.content?.title}
                                      </h4>
                                    </div>
                                  </div>

                                  <div className="flex flex-col xl:flex-row w-full gap-4">
                                    <div className="flex flex-row flex-1">
                                      <h4 className="font-bold text-md text-slate-500 leading-6 flex flex-row items-center">
                                        <Icon
                                          name="UserIcon"
                                          className="w-4 h-4 mr-1"
                                        />
                                        <TooltipWrapper
                                          className="mr-1 my-1"
                                          content={
                                            <span>
                                              {
                                                transaction?.content?.author
                                                  ?.email
                                              }
                                            </span>
                                          }
                                          place="top"
                                        >
                                          {
                                            transaction?.content?.author
                                              ?.username
                                          }
                                        </TooltipWrapper>
                                      </h4>
                                      <UserBadges
                                        user={transaction?.content?.author}
                                        classname="px-2"
                                        noText
                                        isSmall
                                      />
                                    </div>
                                  </div>

                                  <div className="responsive-flex-lg flex-row mt-1">
                                    <div className="text-sm flex basis-1/2">
                                      <div className="flex items-center mr-4">
                                        <span className="font-bold text-lg text-secondary">
                                          {formatPrice(
                                            transaction?.content?.price
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex flex-row basis-1/2 items-center text-ternary-800">
                                      <div className="text-sm flex">
                                        <div className="flex items-center">
                                          <Icon
                                            name="ClockIcon"
                                            className="w-4 h-4 mr-1"
                                          />
                                          <span>
                                            {transaction?.content?.publishedDate
                                              ? dayjs(
                                                  transaction?.content
                                                    ?.publishedDate
                                                ).format("LL")
                                              : t("forms.notPublishedYet")}
                                          </span>
                                        </div>
                                      </div>
                                      {transaction?.content
                                        ?.endPublishedDate && (
                                        <>
                                          <div className="py-1 mx-1">
                                            <FiChevronsRight className="w-3 h-3 text-slate-500" />
                                          </div>
                                          <div className="text-sm flex">
                                            <div className="flex items-center">
                                              <span>
                                                {dayjs(
                                                  transaction?.content
                                                    ?.endPublishedDate
                                                ).format("LL")}
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  <div className="responsive-flex-lg mt-1" />

                                  {/* foot */}
                                  <div className="flex flex-col gap-2 pt-1 border-t border-slate-200">
                                    {content?.description && (
                                      <div className="text-sm text-gray-500 pt-2">
                                        {truncateString(
                                          getFlattenText(
                                            transaction?.content?.description
                                          ),
                                          150
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    </Link>
                  </div>
                </div>

                <div className="white-box flex-col w-full">
                  <SectionTitle
                    title={t("reports.reason")}
                    icon="BookmarkIcon"
                    className="text-secondary"
                  />
                  <div className="responsive-flex py-4 flex-row">
                    {description ? (
                      description.split("\n").map((p: any, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={`p-${index}`} className="mb-4">
                          {p}
                        </p>
                      ))
                    ) : (
                      <p>{t("forms.noContent")}</p>
                    )}
                  </div>
                </div>
              </div>
              {/* Column 2 */}
              <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
                <SectionTitle
                  title={t("forms.author")}
                  icon="UserIcon"
                  className="text-ternary"
                />
                <div className="responsive-flex flex-row gap-8">
                  {author ? (
                    <div>
                      <UserAvatar user={author} growOnSmallDevice={false} />
                      <div className="grid">
                        <div className="font-bold text-lg">
                          {author?.username}
                        </div>
                        <div className="font-bold text-md">{author?.email}</div>
                        <UserBadges
                          user={author}
                          classname="self-justify-end"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="font-bold text-lg">
                      Auteur non renseigné
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDetails;
