import { IRadioBlockItemData } from "common/components/RadioBlock/RadioBlock";

const rolesData: IRadioBlockItemData[] = [
  {
    id: 1,
    icon: "UserAuthenticated",
    value: "Authenticated",
    titleKey: "roles.Authenticated",
    subTitleKey: "roles.AuthenticatedSubtitle",
    containerSelStyle: "bg-blue-400 border-blue-400 shadow-lg blue-400",
    iconStyle: "text-white",
    iconContainerStyle: "bg-blue-400",
    iconSelStyle: "text-blue-400",
    iconContainerSelStyle: "bg-white",
  },
  /*  {
    id: 3,
    icon: "UserAdmin",
    value: "Administrateur",
    titleKey: "roles.Administrateur",
    subTitleKey: "roles.AdministrateurSubtitle",
    containerSelStyle: "bg-blue-400 border-blue-400 shadow-lg blue-400",
    iconStyle: "text-white",
    iconContainerStyle: "bg-blue-400",
    iconSelStyle: "text-blue-400",
    iconContainerSelStyle: "bg-white",
  }, */
  {
    id: 4,
    icon: "UserSuperAdmin",
    value: "SuperAdmin",
    titleKey: "roles.SuperAdmin",
    subTitleKey: "roles.SuperAdminSubtitle",
    containerSelStyle:
      "bg-ternary-400 border-ternary-400 shadow-lg ternary-200",
    iconStyle: "text-white",
    iconContainerStyle: "bg-ternary-400",
    iconSelStyle: "text-ternary-400",
    iconContainerSelStyle: "bg-white",
  },
];

export default rolesData;
