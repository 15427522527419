import {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import {
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
  // ChatBubbleLeftRightIcon,
  ExclamationTriangleIcon,
  ListBulletIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import { Report } from "types/Report";
import Header from "common/components/Header/Header";
import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import useList from "common/hooks/useList";
import FilteredList from "common/components/Table/FilteredList";
import AlertsContext from "common/providers/alerts";
import UserSearch from "components/search/UserSearch";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import TabBlockItem from "common/components/TabBlockItem/TabBlockItem";
import ButtonGroup from "common/components/ButtonGroup/ButtonGroup";
import useReport from "common/hooks/useReport";
import ReportItem from "./ReportItem";

function Reports() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const { reportsNumber, refreshReportsNumber } = useReport();

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error: errorList1,
  } = useList<Report>("reports", {
    defaultSorts: ["createdAt:DESC"],
    populate: [
      "user",
      "user.avatar",
      "user.companyLogo",
      "author",
      "author.avatar",
      "author.companyLogo",
      "content",
      "content.author",
      "content.author.avatar",
      "content.author.companyLogo",
      "content.images",
      "transaction",
      "transaction.content",
      "transaction.content.images",
      "transaction.buyer",
      "transaction.buyer.avatar",
      "transaction.seller",
      "transaction.seller.avatar",
    ],
    defaultFilters: {
      isEmergency: false,
    },
  });

  useEffect(() => {
    if (errorList1) {
      setAlert(errorList1, "danger");
    }
  }, [errorList1, setAlert]);

  const getTypeContent: any = useCallback(
    (item: Report) => {
      if (item.status === "transaction") {
        return {
          name: t("common.transaction"),
          icon: "ChatBubbleLeftRightIcon",
          label:
            item.transaction?.buyer?.username ??
            item.transaction?.buyerName ??
            "Utilisateur introuvable",
        };
      }
      if (item.status === "content") {
        return {
          name: t("common.content"),
          icon: "CalendarDaysIcon",
          label: item.content?.title ?? "Annonce introuvable",
        };
      }
      if (item.status === "user") {
        return {
          name: t("common.user"),
          icon: "UserIcon",
          label: item.user?.username ?? "Utilisateur introuvable",
        };
      }
      return null;
    },
    [t]
  );

  const typeList = useMemo(() => {
    return [
      {
        id: "",
        name: t("status.all"),
        count: reportsNumber?.all || 0,
        icon: ListBulletIcon,
        color: "primary",
      },
      {
        id: "user",
        name: t("common.user"),
        count: reportsNumber?.user || 0,
        icon: UserIcon,
        color: "yellow",
      },
      {
        id: "content",
        name: t("common.content"),
        count: reportsNumber?.content || 0,
        icon: CalendarDaysIcon,
        color: "ternary",
      },
      {
        id: "transaction",
        name: t("common.transaction"),
        count: reportsNumber?.transaction || 0,
        icon: ChatBubbleLeftRightIcon,
        color: "secondary",
      },
    ];
  }, [t, reportsNumber]);

  const reports = useMemo(
    () =>
      items?.map((item) => ({
        ...item,
        typeContent: getTypeContent(item),
      })),
    [getTypeContent, items]
  );

  useEffect(() => {
    refreshReportsNumber();
  }, [refreshReportsNumber]);

  const { removeItem } = useItem<Report>("reports", "");

  const { navigate } = useRouter();

  const handleNavigate = (id: any) => {
    navigate(`/reports/edit/${id}`);
  };

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const columns = useMemo(
    () => [
      /* {
        Header: t("filters.status"),
        headerClasses: "hidden",
        columnClasses: "w-full mb-[-8px]", // mb-[-12px]
        filterName: "status$contains",
        filterComponent: (props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={statusList}
            horizontal
            noGap
            {...props}
          />
        ),
      }, */
      {
        Header: t("reports.author"),
        filterName: "author][username]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("reports.reportedAuthor"),
        filterName: "reportedAuthor][username]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("forms.state"),
        filterName: "state",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <ButtonGroup
            className="w-full"
            options={[
              { value: null, label: t("status.all") },
              { value: "unprocessed", label: t("status.unprocessed") },
              { value: "processed", label: t("status.processed") },
            ]}
            {...props}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.reports")}
        subTitle={t("titleLegends.reports")}
        btnTitle={t("newItem.report")}
        btnSubtitle={t("newItem.reportLegend")}
        Icon={ExclamationTriangleIcon}
        noAdd
        isBeforeFilters
        filters={filters}
        setFilter={setFilter}
        filterName="status$containsi"
        FilterComponent={(props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={typeList}
            horizontal
            noGap
            {...props}
          />
        )}
      />

      <FilteredList
        data={reports ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={ReportItem}
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Reports;
